<div class="col-12 clsMainTitle clsIndexTitle clsAlignVerticalElements">
  <img
    [src]="
      selectedApp.icon === null
        ? '../../../assets/images/icons/app_process.svg'
        : selectedApp.icon
    "
    alt="AppTitle"
    class="clsImageApp flag-icon"
    width="40"
  />
  <span class="clsImageApp_Span">{{ selectedApp.name }}</span>

  <img
    *ngIf="selectedApp.tech === this.globals.tech_Android"
    class="clsTechApp"
    src="../../../../assets/images/badge_android.svg"
  />
  <img
    *ngIf="selectedApp.tech === this.globals.tech_iOS"
    class="clsTechApp"
    src="../../../../assets/images/badge_ios.svg"
  />
  <img
    class="clsTechApp"
    [src]="sourceTypeBadge()"
  />
</div>
