import { Component } from '@angular/core';
import { Globals } from 'src/app/Utils/globals';
import { AppChangeService } from 'src/app/services/app-change.service';

@Component({
  selector: 'app-whitelabel-warning',
  templateUrl: './whitelabel-warning.component.html',
  styleUrls: ['./whitelabel-warning.component.scss'],
})
export class WhitelabelWarningComponent {
  constructor(private globals: Globals, private appChange: AppChangeService) {}

  navigateToRelease() {
    this.appChange.sectionChange.next(this.globals.APPDISTRIBUTION);
  }
}
