<aside
  *ngIf="selectedApp !== null"
  (window:resize)="onResizeAction()"
  class="left-sidebar"
  data-sidebarbg="skin6"
  [ngClass]="{ clsExpandSidebar: isExpandedSidebar }"
>
  <div
    class="scroll-sidebar ps-container ps-theme-default"
    data-ps-id="610c625b-d483-653c-dc1d-14aec1d11559"
  >
    <nav
      class="sidebar-nav"
      [ngClass]="{ 'sidebar-nav-small': isExpandedSidebar === true }"
    >
      <ul id="sidebarnav" *ngIf="selectedSection">
        <li class="sidebar-item">
          <a class="sidebar-link clsLogoA" (click)="toogleOption(globals.DASHBOARD)">
            <img
              [src]="
                !isExpandedSidebar
                  ? '../../../assets/images/lighthouse-logo.svg'
                  : '../../../assets/images/light_house_logo_tablet.png'
              "
              class="clsLogoLH"
            />
          </a>
        </li>
        <li class="sidebar-item" id="leftDropDown">
          <div class="user-profile d-flex no-block dropdown">
            <div
              class="user-content clsCombo clsMenuApps clsCursorPointer"
              (click)="dropdownChange()"
            >
              <div
                class="user-content hide-menu clsCombo clsComboProductos show"
              >
                <a
                  #dropdownElement
                  class="clsComboItemActive"
                  id="CmbProductos"
                  role="button"
                  data-toggle="dropdown"
                >
                  <h5 class="mb-0 user-name font-medium ComboItemActive_Active">
                    <img
                      [src]="
                        selectedApp.icon === null
                          ? '../../../assets/images/icons/app_process.svg'
                          : selectedApp.icon
                      "
                      alt="users"
                      class="clsImageApp flag-icon"
                      width="40"
                    />
                    <span
                      *ngIf="!isExpandedSidebar"
                      class="clsImageApp_Span clsCmbElipsis"
                      >{{ selectedApp.name }}</span
                    >
                    <img
                      *ngIf="!isExpandedSidebar"
                      class="clsComboArrowDropDown"
                      src="../../../assets/images/icons/arrow-dropdown.svg"
                      width="10px"
                      style="
                        float: right;
                        padding-top: 12px;
                        margin-left: 8px;
                        margin-right: 20px;
                      "
                    />
                    <img
                      class="clsItemTechnology"
                      [src]="
                        selectedApp.tech === this.globals.tech_Android
                          ? '../../../assets/images/android.png'
                          : '../../../assets/images/ios.png'
                      "
                    />
                  </h5>
                </a>
                <div
                  #dropdownElement
                  *ngIf="dropDownOpen"
                  class="dropdown-menu clsDivApps"
                >
                  <input
                    type="text"
                    class="dropdown-item clsItemApp clsSearchApps"
                    (keyup)="refreshAppList($event.target.value)"
                  />
                  <div [ngClass]="{ clsDivAppsScroll: appsUser.length > 5 }">
                    <a
                      class="dropdown-item clsItemApp"
                      *ngFor="let app of appsUser"
                      (click)="onSelectionChange(app)"
                    >
                      <img
                        [src]="
                          app.icon === null
                            ? '../../../assets/images/icons/app_process.svg'
                            : app.icon
                        "
                        alt="users"
                        class="clsImageApp"
                        width="40"
                      />
                      <span
                        [attr.test-id]="generateTestId(app)"
                        class="clsImageApp_Span clsCmbElipsis"
                        >{{ app.name }}
                      </span>
                      <img
                        [class]="{
                          clsItemTechnology: !isIEOrEdge,
                          clsItemTechnologyIE: isIEOrEdge
                        }"
                        [src]="
                          app.tech === this.globals.tech_Android
                            ? '../../../assets/images/android.png'
                            : '../../../assets/images/ios.png'
                        "
                      />
                    </a>
                    <a
                      *ngIf="!userInfo.is_tour && userInfo.can_onboard"
                      class="dropdown-item clsItemApp"
                      (click)="onSelectionChange(register)"
                    >
                      <img
                        src="{{ register.icon }}"
                        alt="users"
                        class="clsImageApp"
                        width="40"
                      />
                      <span class="clsImageApp_Span clsCmbElipsis">{{
                        register.name
                      }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li class="sidebar-item">
          <hr
            [ngClass]="{
              clsSeparatorHR: isExpandedSidebar === false,
              'clsSeparatorHR-small': isExpandedSidebar === true
            }"
          />
        </li>

        <li
          *ngIf="visibleSection(globals.DASHBOARD)"
          [attr.section]="globals.DASHBOARD"
          class="sidebar-item"
        >
          <a
            [ngClass]="{
              clsMenuSelect: selectedSection.code === globals.DASHBOARD,
              clsMenuOptionSelected: selectedSection.code === globals.DASHBOARD
            }"
            (click)="toogleOption(globals.DASHBOARD)"
            class="sidebar-link clsCursorPointer clsMenuOption clsOpenDiv"
            aria-expanded="false"
          >
            <i
              class="mnu mnu-dashboard"
              [ngClass]="{ 'mnu-small': isExpandedSidebar === true }"
            ></i>
            <span class="hide-menu">{{
              'component.leftNavBar.dashboard-section' | translate
            }}</span>
          </a>
        </li>
        <li
          *ngIf="visibleSection(globals.CONFIGURE)"
          [attr.section]="globals.CONFIGURE"
          class="sidebar-item"
        >
          <a
            [ngClass]="{
              clsMenuSelect: selectedSection.code === globals.CONFIGURE,
              clsMenuOptionSelected: selectedSection.code === globals.CONFIGURE
            }"
            class="sidebar-link clsCursorPointer clsMenuOption clsOpenDiv"
            (click)="toogleOption(globals.CONFIGURE)"
            aria-expanded="false"
          >
            <i
              class="mnu mnu-configure"
              [ngClass]="{ 'mnu-small': isExpandedSidebar === true }"
            ></i>
            <span class="hide-menu">{{
              'component.leftNavBar.configure-section' | translate
            }}</span>
          </a>
        </li>
        <li
          *ngIf="visibleSection('user')"
          [attr.section]="globals.USERMANAGEMENT"
          class="sidebar-item"
        >
          <a
            [ngClass]="{
              clsMenuSelect: selectedSection.code === globals.USERMANAGEMENT,
              clsMenuOptionSelected:
                selectedSection.code === globals.USERMANAGEMENT
            }"
            class="sidebar-link clsCursorPointer clsMenuOption clsOpenDiv"
            (click)="toogleOption(globals.USERMANAGEMENT)"
            aria-expanded="false"
          >
            <i
              class="mnu mnu-user-management"
              [ngClass]="{ 'mnu-small': isExpandedSidebar === true }"
            ></i>
            <span class="hide-menu">{{
              'component.leftNavBar.user-management-section' | translate
            }}</span>
          </a>
        </li>
        <li
          *ngIf="visibleSection(globals.DEVELOP)"
          [attr.section]="globals.DEVELOP"
          class="sidebar-item"
        >
          <a
            [ngClass]="{
              clsMenuSelect: selectedSection.code === globals.DEVELOP,
              clsMenuOptionSelected: selectedSection.code === globals.DEVELOP
            }"
            class="sidebar-link clsCursorPointer clsMenuOption clsOpenDiv clsMenuOption"
            (click)="toogleOption(globals.DEVELOP)"
            aria-expanded="false"
          >
            <i
              class="mnu mnu-develop"
              [ngClass]="{ 'mnu-small': isExpandedSidebar === true }"
            ></i>
            <span class="hide-menu">{{
              'component.leftNavBar.develop-section' | translate
            }}</span>
          </a>
        </li>
        <li
          *ngIf="visibleSection(globals.APPDISTRIBUTION)"
          [attr.section]="globals.APPDISTRIBUTION"
          class="sidebar-item"
        >
          <a
            [ngClass]="{
              clsMenuSelect: selectedSection.code === globals.APPDISTRIBUTION,
              clsMenuOptionSelected:
                selectedSection.code === globals.APPDISTRIBUTION
            }"
            class="sidebar-link clsCursorPointer clsMenuOption clsOpenDiv"
            (click)="toogleOption(globals.APPDISTRIBUTION)"
            aria-expanded="false"
          >
            <i
              class="mnu mnu-app-distribution"
              [ngClass]="{ 'mnu-small': isExpandedSidebar === true }"
            ></i>
            <span class="hide-menu">
              {{
                'component.leftNavBar.app-distribution-section' | translate
              }}</span
            >
          </a>
        </li>
        <li
          *ngIf="visibleSection(globals.TESTING)"
          [attr.section]="globals.TESTING"
          class="sidebar-item"
        >
          <a
            [ngClass]="{
              clsMenuSelect: selectedSection.code === globals.TESTING,
              clsMenuOptionSelected: selectedSection.code === globals.TESTING
            }"
            class="sidebar-link clsCursorPointer clsMenuOption clsOpenDiv"
            (click)="toogleOption(globals.TESTING)"
            aria-expanded="false"
          >
            <i
              class="mnu mnu-automation-testing"
              [ngClass]="{ 'mnu-small': isExpandedSidebar === true }"
            ></i>
            <span class="hide-menu">{{
              'component.leftNavBar.automation-testing-section' | translate
            }}</span>
          </a>
        </li>
        <li
          *ngIf="visibleSection(globals.SECURITY)"
          [attr.section]="globals.SECURITY"
          class="sidebar-item"
        >
          <a
            [ngClass]="{
              clsMenuSelect: selectedSection.code === globals.SECURITY,
              clsMenuOptionSelected: selectedSection.code === globals.SECURITY
            }"
            class="sidebar-link clsCursorPointer clsMenuOption clsOpenDiv"
            (click)="toogleOption(globals.SECURITY)"
            aria-expanded="false"
          >
            <i
              class="mnu mnu-security-assessment"
              [ngClass]="{ 'mnu-small': isExpandedSidebar === true }"
            ></i>
            <span class="hide-menu">{{
              'component.leftNavBar.security-section' | translate
            }}</span>
          </a>
        </li>
        <li
          *ngIf="visibleSection(globals.PUBLISH)"
          [attr.section]="globals.PUBLISH"
          class="sidebar-item"
        >
          <a
            [ngClass]="{
              clsMenuSelect: selectedSection.code === globals.PUBLISH,
              clsMenuOptionSelected: selectedSection.code === globals.PUBLISH
            }"
            class="sidebar-link clsCursorPointer clsMenuOption clsOpenDiv"
            (click)="toogleOption(globals.PUBLISH)"
            aria-expanded="false"
          >
            <i
              class="mnu mnu-publish"
              [ngClass]="{ 'mnu-small': isExpandedSidebar === true }"
            ></i>
            <span class="hide-menu">{{
              'component.leftNavBar.publish-section' | translate
            }}</span>
          </a>
        </li>
        <li
          *ngIf="visibleSection(globals.ENGAGE)"
          [attr.section]="globals.ENGAGE"
          class="sidebar-item"
        >
          <a
            [ngClass]="{
              clsMenuSelect: selectedSection.code === globals.ENGAGE,
              clsMenuOptionSelected: selectedSection.code === globals.ENGAGE
            }"
            class="sidebar-link clsCursorPointer clsMenuOption"
            (click)="toogleOption(globals.ENGAGE)"
            aria-expanded="false"
          >
            <i
              class="mnu mnu-engage"
              [ngClass]="{ 'mnu-small': isExpandedSidebar === true }"
            ></i>
            <span class="hide-menu">{{
              'component.leftNavBar.engage-section' | translate
            }}</span>
          </a>
        </li>
        <li
          *ngIf="visibleSection(globals.MEASURE)"
          [attr.section]="globals.MEASURE"
          rmifnotlive="true"
          class="sidebar-item"
        >
          <a
            [ngClass]="{
              clsMenuSelect: selectedSection.code === globals.MEASURE,
              clsMenuOptionSelected: selectedSection.code === globals.MEASURE
            }"
            class="sidebar-link clsCursorPointer clsMenuOption measure-link"
            (click)="loadUrlMeasure()"
            aria-expanded="false"
          >
            <i
              class="mnu mnu-measure-learn"
              [ngClass]="{ 'mnu-small': isExpandedSidebar === true }"
            ></i>
            <span class="hide-menu">{{
              'component.leftNavBar.measure-learn-section' | translate
            }}</span>
            <i class="clsLinkExt"></i>
          </a>
        </li>
        <li class="nav-small-cap">
          <i class=""></i> <span class="hide-menu"></span>
        </li>
        <li
          class="sidebar-item clsUtilsItem"
          [ngClass]="{ clsMarginBottom100px: userInfo.is_tour }"
        >
          <a
            class="sidebar-link clsCursorPointer clsMenuOption"
            (click)="onClickSignOut()"
          >
            <i class="mnu mnu-sign-out"></i>
            <span class="hide-menu">{{ 'common.sign-out' | translate }}</span>
          </a>
          <a
            class="sidebar-link clsCursorPointer clsMenuOption"
            (click)="toogleMenu()"
          >
            <i class="mnu mnu-collapse"></i>
          </a>
        </li>
        <li
          class="sidebar-item"
          *ngIf="isExpandedSidebar"
          [ngClass]="{ clsMarginBottom100px: userInfo.is_tour }"
        >
          <a
            class="sidebar-link clsCursorPointer clsMenuOption"
            (click)="toogleMenu()"
          >
            <i class="mnu mnu-expand"></i>
          </a>
        </li>

        <li class="nav-small-cap">
          <i class=""></i> <span class="hide-menu"></span>
        </li>
      </ul>
    </nav>
  </div>
</aside>
