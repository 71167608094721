import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-dot',
  templateUrl: './notification-dot.component.html',
  styleUrls: ['./notification-dot.component.scss'],
})
export class NotificationDotComponent implements OnInit {
  @Input() showNotificationNumber = true;
  @Input() notificationsNumber = 0;
  @Input() size: 'small' | 'normal' = 'normal';

  ngOnInit() {
    if (this.size === 'small') {
      this.showNotificationNumber = false;
    }
  }
}
