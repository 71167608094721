import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppChangeService {
  appChange = new Subject<any>();
  appChange$: Observable<any> = this.appChange.asObservable();

  formChange = new Subject<any>();
  formChange$: Observable<any> = this.formChange.asObservable();

  sectionChange = new Subject<any>();
  sectionChange$: Observable<any> = this.sectionChange.asObservable();
}
