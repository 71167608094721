import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Action } from 'src/app/model/action.model';
import { Application } from 'src/app/model/application.model';
import { User } from 'src/app/model/user';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { Globals } from 'src/app/Utils/globals';
import { marked } from 'marked';
import { Subscription } from 'rxjs';
import { AppChangeService } from 'src/app/services/app-change.service';
import { CreateVersionService } from 'src/app/modules/distribution/services/create-version.service';
import { Commit } from 'src/app/model/commit.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { StatusOverviewComponent } from './status-overview/status-overview.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  selectedApp: Application;
  user: User;

  news: any;
  showNews: boolean;
  qualityGate: any;
  latestChangesList: any;
  appVersions: any;
  reloading: boolean;
  eventSubscription: Subscription;
  commitList: Commit[];
  openOverlay: boolean;
  projectQualityObject: any;
  @ViewChild(StatusOverviewComponent)
  statusOverviewComponent: StatusOverviewComponent;

  constructor(
    public globals: Globals,
    public service: DashboardService,
    private authService: AuthService,
    private appChangeService: AppChangeService,
    private createVersionService: CreateVersionService,
    private activateRoute: ActivatedRoute,
  ) {
    [this.user, this.selectedApp] = activateRoute.snapshot.data.authData;
  }

  ngOnInit(): void {
    this.subscribeToAppChange();
    this.getNews();
    this.getDashboardInfo();
    this.getProjectStatus();
  }

  getDashboardInfo() {
    this.getAppVersions();
    this.getCommitList();
  }

  getProjectStatus(): any {
    this.service
      .getProjectStatus(this.selectedApp.uuid)
      .subscribe((res: any) => {
        this.projectQualityObject = res;
      });
  }
  checkIfVisible(currentAction: string): boolean {
    const action: Action = this.selectedApp.permissions.sections
      .find((section) => section.code === this.globals.DASHBOARD)
      .actions.find((action) => action.code === currentAction);
    return action != null;
  }

  reloadChart(): void {
    const divContentChart = document.getElementById('divContentChart');
    if (divContentChart) {
      divContentChart.click();
    }
  }

  getNews() {
    const version = localStorage.getItem('news');
    this.getReleaseNotes(version);
  }

  hideNews() {
    this.showNews = false;
    const news = localStorage.getItem('news');

    if (news) {
      localStorage.removeItem('news');
    }

    localStorage.setItem('news', `${this.news.version}`);
  }

  getReleaseNotes(version: string) {
    const params = {
      package: this.selectedApp.package,
      technology: this.selectedApp.tech,
    };

    this.service.getNewsRelease(params).subscribe((news: any) => {
      this.news = this.service.jsonParse(news);

      if (version) {
        version === this.news.version
          ? (this.showNews = false)
          : this.showReleaseNotes();
        return;
      }

      // if (this.news) {
      //   this.showReleaseNotes();
      // }
    });
  }

  private showReleaseNotes() {
    this.showNews = true;
    const description = document.getElementById('description');
    description.innerHTML = marked(this.news.description);
  }

  getAppVersions() {
    this.reloading = true;

    const params = {
      package: this.selectedApp.package,
      technology: this.selectedApp.tech,
    };

    this.service.getAppVersions(params).subscribe(
      (versions: any) => {
        this.appVersions = this.service.jsonParse(versions);
      },
      (error: any) => {},
      () => {
        this.reloading = false;
      },
    );
  }

  getCommitList() {
    const params = {
      package: this.selectedApp.package,
      technology: this.selectedApp.tech,
    };

    this.createVersionService
      .getCommitList(this.selectedApp.uuid)
      .subscribe((commits: Commit[]) => {
        this.commitList = commits;
      });
  }

  subscribeToAppChange() {
    this.eventSubscription = this.appChangeService.appChange$.subscribe(
      (app: Application) => {
        this.selectedApp = app;
        this.getDashboardInfo();
      },
    );
  }
}
