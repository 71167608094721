import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-no-app',
  templateUrl: './landing-no-app.component.html',
  styleUrls: ['./landing-no-app.component.scss'],
})
export class LandingNoAppComponent implements OnInit {
  user: User;
  navigation: any;
  constructor(private router: Router, private service: CommonService) {
    this.navigation = this.router.getCurrentNavigation();
  }

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    const state = this.navigation.extras.state as { user: User };
    if (state) {
      this.user = state.user;
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  takeATour(): void {
    (window.location.href = environment.urls.apiBaseUrl + +'login?op=tour'),
      '_blank';
  }
}
