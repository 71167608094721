<div
  class="clsBorder container-fluid container-file-upload clsBorder_approved"
  *ngIf="archerInReview"
>
  <img
    class="imgRisk"
    src="../../assets/images/icons/success.svg"
    width="40"
    height="40"
    alt=""
  />
  <div class="ellipsis w-100">
    <div class="clsContainer-info ellipsis">
      <div class="ellipsis">
        <span
          class="evidence-detail-title ellipsis"
          title="{{ archerInReview.id }}"
        >
          {{ archerInReview.id }} </span
        ><span class="evidence-detail-title">{{
          'component.security-assessment.functional-checks.has-been-approved'
            | translate
        }}</span>
      </div>
    </div>
    <div class="evidence-description review ellipsis">
      <span> {{ archerInReview.title }}</span>
    </div>
    <div class="evidence-description ellipsis">
      <span>
        {{
          'component.security-assessment.functional-checks.tbl-header-status'
            | translate
        }}:
        <span class="evidence-date">{{
          archerInReview.status | capitalLetter
        }}</span></span
      >
      <span>
        {{
          'component.security-assessment.functional-checks.due-date'
            | translate
        }}:
        <span class="evidence-date">{{
          archerInReview.due_date + 'Z' | date: 'dd-MMM-yy'
        }}</span></span
      >
    </div>
    <div class="evidence-link-section">
      <a class="evidence-link see-all" [href]="archerInReview.url">
        {{
          'component.security-assessment.functional-checks.more-info'
            | translate
        }}
      </a>
    </div>
  </div>
</div>
<div [hidden]="archerInReview || confirmationStep">
  <app-risk-acceptance
    [appUuid]="uuid"
    [evidenceId]="securityFindingId"
    [securityArcher]="true"
    (emitRisk)="goToConfirmation($event)"
  ></app-risk-acceptance>
</div>
<div *ngIf="confirmationStep">
  <div class="clsConfirmationTitleContainer">
    <span class="clsConfirmationTitle">
      {{
        'component.security-assessment.functional-checks.confirmation-title'
          | translate
      }}
    </span>
  </div>
  <div *ngFor="let target of targets">
    <span class="clsConfirmationTarget">{{ target.text }}</span>
  </div>
  <div *ngIf="!submitting" class="clsFooterRow">
    <button class="clsCancelButton" (click)="toggleStep()">
      {{ 'common.no' | translate }}
    </button>
    <button class="clsConfirmButton" (click)="uploadRiskAcceptance()">
      {{ 'common.yes' | translate }}
    </button>
  </div>
  <div class="text-center loadingIcon" *ngIf="submitting">
    <img
      class="clsImgCenterVertical"
      src="../../../../../../assets/images/icons/reload_big_pink.gif"
    />
  </div>
</div>
