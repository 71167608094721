<div
  #bellElement
  id="NotificationBell"
  class="clsNotificationBell clsCursorPointer clsAutoEvent"
  (click)="showNotifications()"
  [ngClass]="{
    'liveAppHasSecurityIssues': liveVersionSecurityBannerData !== null
  }"
>
  <i
    id="NotificationBellIcon"
    class="clsNotificationBellIcon"
    [ngClass]="{
      clsNotificationBellIconFaded: !dialogOpen,
      clsNotificationBellIconOff: !hasUnreadNotifications
    }"
  ></i>
  <app-notification-dot
    class="clsNotificationDot"
    *ngIf="hasUnreadNotifications"
    [notificationsNumber]="unreadNotificationsNumber"
  ></app-notification-dot>
</div>
