import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import translations from 'src/assets/i18n/en-EN.json';

class MappTranslateLoader implements TranslateLoader {
  constructor() {}
  getTranslation(lang) {
    // Important: since now the only available lang is en-EN,
    // we currently ignore the param. In case of a future multilang needed,
    // a lazy loading approach should be implemented
    return new Observable((observer) => {
      observer.next(translations);
      observer.complete();
    });
  }
}

export const MappTranslateModule = TranslateModule.forRoot({
  defaultLanguage: 'en-EN',
  useDefaultLang: true,
  loader: {
    provide: TranslateLoader,
    useFactory: () => {
      return new MappTranslateLoader();
    },
  },
});
