import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceCapitals',
})
export class SpaceCapitalsPipe implements PipeTransform {
  transform(value: string): unknown {
    if (!value) {
      return value;
    }
    return value.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}
