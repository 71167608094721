<app-navbar-landing></app-navbar-landing>
<div class="container-fluid clsLandingContainer no-padding">
  <div class="row">
    <div class="col-12 no-padding">
      <div class="row">
        <div
          *ngIf="!user?.pending_onboards"
          class="container-fluid clsContainerReadyToStart no-padding"
        >
          <div class="row">
            <div
              class="col-12 text-center pb-3"
              id="divIconProcess"
              style="display: none"
            >
              <img
                alt=""
                src="../../../assets/images/icons/app_process.svg"
                width="68"
                height="68"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 clsReadyToStart_Title pb-3" id="divLnaTitleText">
              {{ 'component.landingnoapp.readytostart' | translate }}
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 clsReadyToStart_Subtitle pb-3"
              id="divLnaSubtitleText"
            >
              {{ 'component.landingnoapp.registeryourapp' | translate }}
            </div>
          </div>
        </div>
        <div
          *ngIf="user?.pending_onboards"
          class="container-fluid clsContainerReadyToStart"
        >
          <div class="row">
            <div
              class="col-12 text-center pb-3"
              id="divIconProcess"
              style="display: block"
            >
              <img
                alt=""
                src="../../../assets/images/icons/app_process.svg"
                width="68"
                height="68"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 clsReadyToStart_Title pb-3" id="divLnaTitleText">
              {{ 'component.landingnoapp.onboardinginprocess' | translate }}
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 clsReadyToStart_Subtitle pb-3"
              id="divLnaSubtitleText"
            >
              {{ 'component.landingnoapp.beingonboarded' | translate }}
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 text-center pb-4"
              id="divBtnTakeatourTop"
              style="display: block"
            >
              <button class="clsButtonTakeATour" (click)="takeATour()">
                {{ 'component.landingnoapp.takeatour' | translate }} &nbsp;
                <img
                  alt=""
                  class="clsIconButtonGetStarted"
                  src="../../../assets/images/icons/arrow_right_darkblue.svg"
                  width="15"
                  height="15"
                />
              </button>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 clsReadyToStart_Subtitle pt-3"
              id="divLnaOr"
              style="display: block"
            >
              {{ 'component.landingnoapp.or' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div
              class="container clsContainerReadyToStart_AddYourApp text-center"
            >
              <a routerLink="/Main/Onboarding">
                <div class="row h-100">
                  <div class="col-12 mt-auto">
                    <img
                      alt=""
                      src="../../../assets/images/icons/add_darkblue.svg"
                      width="68"
                      height="68"
                    />
                  </div>
                  <div class="col-12 clsReadyToStartAddYourApp">
                    {{ 'component.landingnoapp.addyourapp' | translate }}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row clsSectionSeparation clsReadyToStart_BGBlue"
        [ngClass]="{
          moreBottom: !user?.pending_onboards,
          lessBottom: user?.pending_onboards
        }"
      ></div>
    </div>
  </div>
</div>
