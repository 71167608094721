<div class="overlay-msg">
  <span [@fade]="currentText.length" class="overlay-text"
    ><p>{{ currentText }}</p></span
  >
  <div class="overlay-buttons">
    <div class="overlay-btn">
      <span (click)="getSuccessMethod()">{{ 'common.yes' | translate }}</span>
    </div>
    <div class="overlay-btn">
      <span (click)="getDeclineMethod()">{{ 'common.no' | translate }}</span>
    </div>
  </div>
</div>
