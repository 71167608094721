import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../../services/common.service';

@Injectable({
  providedIn: 'root',
})
export class AppMetaDataService extends CommonService {
  publishForm = new Subject<any>();
  publishForm$: Observable<any> = this.publishForm.asObservable();
  formChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  formValid: BehaviorSubject<boolean> = new BehaviorSubject(false);
  saveMetaDataMethod = new Subject<any>();
  saveMetaDataDone: BehaviorSubject<boolean> = new BehaviorSubject(false);
  screenShotError = new Subject<string>();

  callSaveMetaDataMethod() {
    this.saveMetaDataMethod.next(null);
  }

  addLanguage(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'publish/add_language',
      params,
    );
  }

  deleteLanguage(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'publish/delete_language',
      params,
    );
  }

  getAvailableLanguages(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'publish/get_available_languages',
      params,
      { withCredentials: true },
    );
  }

  getProjectLanguages(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'publish/get_project_languages',
      params,
    );
  }

  getMetaDataFromProject(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'publish/get_metadata',
      params,
    );
  }

  saveMetaData(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'publish/save_metadata',
      params,
    );
  }

  uploadExistingMetaData(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'publish/upload_metadata',
      params,
    );
  }

  uploadScreenShot(params: any, appuid) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + `apps/${appuid}/screenshots/upload`,
      params,
    );
  }

  deleteScreenShot(appuid, screenshotId) {
    return this.httpClient.delete<any>(
      environment.urls.apiBaseUrl +
        `apps/${appuid}/screenshots/${screenshotId}`,
    );
  }
}
