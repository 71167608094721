import { Component, Input, OnInit } from '@angular/core';
import { Notification } from './../../../../model/notification';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: Notification;

  DEFAULT_ICON = '/assets/images/icons/lighthouse-icon.png';
  appIcon: string;
  techUrl: string;
  constructor() {}

  ngOnInit() {
    this.appIcon = this.notification.tech
      ? this.notification.icon
      : this.DEFAULT_ICON;

    this.techUrl = this.notification.tech
      ? `/assets/images/${this.notification.tech}.png`
      : '';
  }
}
