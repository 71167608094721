import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
})
export class LoadingModalComponent {
  constructor(
    public dialogRef: MatDialogRef<LoadingModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  cancelbtn(): void {
    this.dialogRef.close();
  }
}
