<div class="app-tabs-container">
  <div
    (click)="handleTabSelect(tab.value)"
    [ngClass]="{ 'app-tab-element-selected': tab.value === currentTab }"
    *ngFor="let tab of appTabElements"
    class="app-tab-element"
  >
    <p>
      {{ tab.label }} <span class="required-tab" *ngIf="tab.required"> *</span>
    </p>
  </div>
</div>
