import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';
import { TranslateService } from '@ngx-translate/core';
import { GeneralModalComponent } from '../modules/shared/general-modal/general-modal.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  width: string;
  height: string;
  panelClass: string;
  buttons: any[];
  texts: any;
  disabledClose: boolean;
  func: any;
  icon: any;
  redirectData: any;

  translate: TranslateService;
  objMatDialogRef: MatDialogRef<any>;
  objMatDialogConfig: MatDialogConfig;

  constructor(private objMatDialog: MatDialog) {}

  openDialog() {
    this.width = !this.width ? '450px' : this.width;
    this.height = !this.height ? '480px' : this.height;
    this.panelClass = 'dialogPanelClass';

    if (this.buttons == null || this.buttons.length == 0) {
      this.buttons = [
        {
          btnText: this.translate.instant('component.general-modal.cancel'),
          btnFunc: function (pThisDlg: any) {
            pThisDlg.close();
          },
        },
        {
          btnText: this.translate.instant('component.general-modal.accept'),
          btnFunc: function (pThisDlg: any) {
            pThisDlg.close();
          },
        },
      ];
    }

    this.disabledClose = true;
    this.func = null;

    if (this.icon == null) {
      this.icon = {
        src: '../../../assets/images/icons/info.svg',
        class: 'clsIcon80x80',
      };
    }

    this.objMatDialogConfig = {
      width: this.width,
      height: this.height,
      panelClass: this.panelClass,
      data: {
        buttons: this.buttons,
        texts: this.texts,
        icon: this.icon,
        redirectData: this.redirectData,
      },
      disableClose: this.disabledClose,
    };
    this.objMatDialogRef = this.objMatDialog.open(
      GeneralModalComponent,
      this.objMatDialogConfig,
    );
  }
}
