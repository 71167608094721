export class KpiHistory {
  date: string;
  last_dau: number;
  last_wau: number;
  last_mau: number;
  last_rating: string;
  total_downloads: number;
  total_crashes: number;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
