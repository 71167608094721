import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Application } from 'src/app/model/application.model';
import { KpiHistory } from 'src/app/model/kpi-history.model';
import { ResponseObject } from 'src/app/model/response-object.model';
import { User } from 'src/app/model/user';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';

@Component({
  selector: 'app-live-stats',
  templateUrl: './live-stats.component.html',
  styleUrls: ['./live-stats.component.scss'],
})
export class LiveStatsComponent implements OnInit, OnChanges {
  @Input() selectedApp: Application;
  @Input() user: User;

  reloadingKpi: boolean = false;
  reloadingChart: boolean = false;
  overallKpiObject: any;
  kpiObjectList: KpiHistory[];

  constructor(private dashboardService: DashboardService) {}

  ngOnInit() {
    this.getOverAllkpis();
    this.getHistoricalkpis();
  }

  ngOnChanges() {
    //TODO not to reload if we still have the values for the same app and same section
    this.getOverAllkpis();
    this.getHistoricalkpis();
  }

  getHistoricalkpis(): void {
    this.reloadingKpi = true;
    const params = {
      package: this.selectedApp.package,
      technology: this.selectedApp.tech,
    };
    this.dashboardService
      .getDashboardOverallHistoricalKips(params)
      .subscribe((res: ResponseObject) => {
        this.reloadingKpi = false;
        if (res.is_ok) {
          this.kpiObjectList = this.parseResultToObject(
            JSON.parse(res.original_response),
          );
        }
      });
  }

  parseResultToObject(kapiList: JSON[]): KpiHistory[] {
    const resultList: KpiHistory[] = new Array<KpiHistory>();
    kapiList.forEach((kpi) => {
      resultList.push(new KpiHistory(kpi));
    });
    return resultList;
  }
  getOverAllkpis(): void {
    const params = {
      package: this.selectedApp.package,
      technology: this.selectedApp.tech,
    };
    this.dashboardService
      .getOverAllKpis(params)
      .subscribe((res: ResponseObject) => {
        if (res.is_ok) {
          this.overallKpiObject = JSON.parse(res.original_response);
        }
      });
  }
}
