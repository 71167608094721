import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-info-status-card',
  templateUrl: './app-info-status-card.component.html',
  styleUrls: ['./app-info-status-card.component.scss'],
})
export class AppInfoStatusCardComponent implements OnChanges {
  @Input() isSuccess: boolean;
  @Input() versionTitle: string;
  @Input() versionValue: string;
  @Input() dateTitle: string;
  @Input() dateValue: string;
  @Input() statusTitle: string;
  @Input() statusAlert: string;
  @Input() statusMessage: string;
  @Input() compactStyle: boolean = false;

  successImgPath: string = 'assets/images/icons/success.svg';
  errorImgPath: string = '../../../assets/images/icons/error.svg';
  infoStatusImgPath: string = this.errorImgPath;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSuccess) {
      this.setInfoStatusImg();
    }
  }

  setInfoStatusImg() {
    this.infoStatusImgPath = this.isSuccess
      ? this.successImgPath
      : this.errorImgPath;
  }
}
