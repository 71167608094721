import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as signalR from '@microsoft/signalr';
import { Notification } from 'src/app/model/notification';
import { NotificationsService } from 'src/app/services/notifications.service';
import { environment } from 'src/environments/environment';
import { NotificationToastComponent } from './notifiaction-toast/notification-toast.component';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { GlobalsService } from 'src/app/services/globals.service';
import { LiveVersionSecurityBannerData } from 'src/app/shared/models/liveVersionSecurityBannerData';

interface NotificationResponse {
  notifications: Notification[];
  unread_notifications: [{ category: string; count: number }];
}
@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
})
export class NotificationBellComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private service: NotificationsService,
    private _snackBar: MatSnackBar,
    private readonly globalsService: GlobalsService,
  ) {}

  dialogOpen = false;
  notificationsList: Notification[] = [];
  hasUnreadNotifications: boolean = false;
  unreadNotificationsNumber = 0;
  liveVersionSecurityBannerData: LiveVersionSecurityBannerData;

  ngOnInit(): void {
    this.connectNotifications();
    this.globalsService.liveVersionSecurityBannerData$.subscribe(
      (res) => (this.liveVersionSecurityBannerData = res),
    );
  }

  connectNotifications() {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.urls.apiBaseUrl + 'cross/notifications')
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on('onNotificationReceived', () => {
      this.getNotifications();
    });

    connection.start().catch(console.error);

    connection.onclose(() => {
      setTimeout(() => {
        connection.start();
      }, 1000); // Restart connection after 1 seconds.
    });

    this.getNotifications();
  }

  getNotifications(): void {
    this.service.getNotifications().subscribe((res: NotificationResponse) => {
      this.notificationsList = res.notifications;

      this.checkUnreadNotifications();
      this.openSnackBar(
        this.notificationsList.find(
          (notification) => notification.timestamp + 5000 >= Date.now(),
        ),
      );
    });
  }

  checkUnreadNotifications(): void {
    this.unreadNotificationsNumber = this.notificationsList.filter(
      (notification) => !notification.read,
    ).length;
    this.hasUnreadNotifications = this.notificationsList.some(
      (notification) => notification.read === false,
    );

    const PlatformCategory = 'Platform';

    const lastPlattformNotification = this.notificationsList
      .filter((notification) => notification.category === PlatformCategory)
      .reduce((prev, current) => {
        return prev.timestamp > current.timestamp ? prev : current;
      });

    if (
      this.notificationsList.length &&
      lastPlattformNotification &&
      !lastPlattformNotification.read
    ) {
      this.showNotificationModal(lastPlattformNotification);
    }
  }

  showNotificationModal(lastNotification: Notification) {
    const dialogRef = this.dialog.open(NotificationModalComponent, {
      width: '729px',
      height: '551px',
      panelClass: 'notificationModalPanelClass',
      data: { notification: lastNotification },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((notification) => {
      this.service.notificationRead(notification.id).subscribe(() => {
        this.notificationsList.forEach((not) => {
          if (not.id === notification.id) {
            not.read = true;
          }
        });
        this.checkUnreadNotifications();
      });
    });
  }

  openSnackBar(notification: Notification) {
    if (!notification) {
      return false;
    }
    this._snackBar.openFromComponent(NotificationToastComponent, {
      duration: 5000,
      data: notification,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  showNotifications(): void {
    this.dialogOpen ? this.closeLoadingDialog() : this.openLoadingDialog();
  }

  openLoadingDialog(): void {
    this.dialogOpen = true;
    const dialogRef = this.dialog.open(NotificationCenterComponent, {
      width: '700px',
      height: '651px',
      panelClass: 'notificationPanelClass',
      data: { notificationsList: this.notificationsList },
      disableClose: true,
    });
    dialogRef.componentInstance.updateNotificationsEmitter.subscribe(() => {
      this.checkUnreadNotifications();
    });
    dialogRef.afterClosed().subscribe(() => {
      this.closeLoadingDialog();
    });
  }
  closeLoadingDialog(): void {
    this.dialogOpen = false;
    this.dialog.closeAll();
  }
}
