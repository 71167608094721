import { Component } from '@angular/core';

@Component({
  selector: 'app-tour-navbar',
  templateUrl: './tour-navbar.component.html',
  styleUrls: ['./tour-navbar.component.scss'],
})
export class TourNavbarComponent {
  constructor() {}
}
