<div class="container-fluid clsContainerDataMain">
  <div class="row">
    <div class="col-12 clsMainTitle clsIndexTitle clsAlignVerticalElements">
      <app-dashboard-header
        [selectedApp]="selectedApp"
        [user]="user"
      ></app-dashboard-header>
    </div>
  </div>

  <div class="row news" [hidden]="!showNews">
    <div class="col-12 release-message" id="description"></div>
    <img
      src="../../../assets/images/icons/decline-white.svg"
      alt="closeIcon"
      class="clsCursorPointer"
      (click)="hideNews()"
    />
  </div>

  <div *ngIf="checkIfVisible(globals.ACC_LIVE_STATS)" class="row">
    <div class="col-12 clsMainTitle clsIndexTitle clsAlignVerticalElements">
      <app-live-stats
        [selectedApp]="selectedApp"
        [user]="user"
      ></app-live-stats>
    </div>
  </div>

  <div class="row" *ngIf="checkIfVisible(globals.ACC_LATEST_VERSION)">
    <div class="col-lg-12">
      <app-status-overview
        [appVersions]="appVersions"
        [commitList]="commitList"
        [projectQualityObject]="projectQualityObject"
      ></app-status-overview>
    </div>
  </div>
  <app-live-version-status id="liveVersionStatus"></app-live-version-status>
</div>
