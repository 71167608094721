import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalLetter',
  pure: true,
})
export class CapitalLetterPipe implements PipeTransform {
  constructor() {}

  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    let lValue: string = value.toLowerCase();
    lValue = lValue
      .replace('closed_by_policy', 'Closed by policy')
      .replace('closed_item_', '')
      .replace('closed_', '')
      .replace('_', ' ');

    const camel: string = lValue.charAt(0).toUpperCase() + lValue.slice(1);
    return camel;
  }
}
