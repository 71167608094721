import { Injectable } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppVersion } from '../../model/appVersion';
import { ToolConfig } from '../../model/toolConfig.model';

@Injectable({
  providedIn: 'root',
})
export class AutomationTestingService extends CommonService {
  testStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  paginatorSize: BehaviorSubject<number> = new BehaviorSubject<number>(8);

  public runTestAppVersion(appGuid: string, appVersion: AppVersion) {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl +
        `apps/${appGuid}/version/${appVersion}/test`,
      { withCredentials: true },
    );
  }

  public getLatestTest(appGuid: string) {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl + `apps/${appGuid}/tests`,
      { withCredentials: true },
    );
  }

  public getReleaseCandidates(appGuid: string) {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl + `apps/${appGuid}/tests/versions`,
      { withCredentials: true },
    );
  }

  public getRepository(appGuid: string) {
    return this.httpClient.get<ToolConfig>(
      environment.urls.apiBaseUrl + `/apps/${appGuid}/tools/testing/config`,
      { withCredentials: true },
    );
  }

  public getTestingReport(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'testing/report',
      params,
      { withCredentials: true },
    );
  }

  public getTestingDevices(app, testId) {
    return this.httpClient
      .get<any>(
        environment.urls.apiBaseUrl + `apps/${app}/tests/${testId}/devices`,
      )
      .pipe(
        map((data) => {
          return data.devices.map((device) => {
            return {
              deviceOS: device.device_OS,
              deviceDpiName: device.device_dpiName,
              deviceName: device.device_name,
              deviceVersion: device.device_version,
              jobId: device.job_id,
              jobStatus: device.job_status,
            };
          });
        }),
      );
  }

  public getJobDetail(app, testId, jobId) {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl +
        `apps/${app}/tests/${testId}/devices/${jobId}`,
    );
  }

  public getFullReport(app, testId, jobId) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl +
        `apps/${app}/tests/${testId}/devices/${jobId}/report`,
      {},
    );
  }

  public getVideoReport(app, testId, jobId) {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl +
        `apps/${app}/tests/${testId}/devices/${jobId}?type=video`,
      { responseType: 'blob' as 'json' },
    );
  }
}
