<div class="clsCardModal">
  <div class="modal-body break-word" style="padding: 0">
    <div style="width: 100%; height: auto; text-align: center">
      <img class="{{ icon.class }}" src="{{ icon.src }}" />
      <div class="clsDlgInfo_Title">{{ title }}</div>
      <div class="clsDlgInfo_Text" [innerHtml]="main"></div>
      <div class="clsDlgInfo_Text clsDlgInfo_Text--small" [innerHtml]="mainSmall"></div>
      <div *ngIf="redirectData">
        <br /><br />
        {{ redirectData.text }}
        <a [href]="redirectData.link" target="_blank">{{
          redirectData.clickWord
        }}</a>
      </div>
      <div class="clsDlgInfo_Subtext">{{ subtext }}</div>
    </div>
  </div>
  <div
    class="clsBtnsFooter"
    [ngClass]="{ clsJustifyContentCenter: buttons.length === 1 }"
  >
    <span
      *ngFor="let item of buttons"
      (click)="item.btnFunc(this.dialogRef)"
      class="clsBtnDlg {{ item.btnText }}"
      style="cursor: pointer"
      data-dismiss="modal"
      >{{ item.btnText }}</span
    >
  </div>
</div>
