<div
  id="dialogAutoId_1_body"
  dlgid="dialogAutoId_1"
  class="modal-body break-word"
>
  <div id="dialogAutoId_1_body_content">
    <div class="clsDialogContent">
      <img class="clsIcon80x80" src="../../../assets/images/icons/error.svg" />
      <div class="clsSA_IconTitle mt-auto clsQualityGateSA_Text">
        {{ text }}
      </div>
      <div class="clsSubtext">{{ subtext }}<br />{{ subtext2 }}</div>
    </div>
  </div>
</div>
<div id="dialogAutoId_1_footer" class="modal-footer">
  <span id="dialogAutoId_1_btnCancel" (click)="acceptBtn()" class="clsBtnDlg">{{
    btntext
  }}</span>
</div>
