import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marked } from 'marked';

@Component({
  selector: 'app-accept-risk-modal',
  templateUrl: './accept-risk-modal.component.html',
  styleUrls: ['./accept-risk-modal.component.scss'],
})
export class AcceptRiskModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AcceptRiskModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  markItem(text) {
    return marked(text);
  }
  cancel() {
    this.dialogRef.close();
  }

  accept() {
    this.dialogRef.close(true);
  }
}
