<div class="card clsContainerGradient_ColorDarkBlue">
  <div class="container-fluid">
    <div class="row">
      <div class="col-9">
        <div
          class="clsContainerGradient_Title"
          [ngClass]="{
            clsMarginTop24px: !repoUrl || howToWork === true
          }"
        >
          {{ title | translate }}
        </div>
        <div
          class="clsContainerGradient_Subtitle"
          [ngClass]="{
            'less-bottom-space': repoUrl || howToWork === true
          }"
        >
          {{ subtitle | translate }}
        </div>
        <button
          *ngIf="repoUrl"
          class="
            clsContainerGradient_btn clsContainerGradient_btnRepo
            clsMarginLeftBtn
          "
          (click)="
            this.user.is_tour
              ? this.service.optNotAvailable()
              : repositoryBtn($event)
          "
        >
          <img
            class="clsContainerGradient_ImgBtn"
            src="../../../assets/images/icons/repository-icon.png"
            width="20"
            height="16"
          />
          {{ 'component.develop.container.repo' | translate }}
        </button>
        <button
          *ngIf="howToWork === true"
          class="clsContainerGradient_btn clsContainerGradient_btnHowToWork"
          [ngClass]="{ clsMarginLeftBtn: !repoUrl }"
          (click)="showHowToWork()"
        >
          <img
            class="clsContainerGradient_ImgBtn"
            src="../../../assets/images/icons/info-white.png"
            width="20"
            height="20"
          />
          {{ 'component.develop.container.howToWork' | translate }}
        </button>
        <button
          *ngIf="optIn === true"
          class="clsContainerGradient_btn clsContainerGradient_btnHowToWork"
          [ngClass]="{ clsMarginLeftBtn: !repoUrl }"
          (click)="showHowToWork()"
        >
          <img
            class="clsContainerGradient_ImgBtn"
            src="../../../assets/images/icons/info-white.png"
            width="20"
            height="20"
          />
          {{ 'component.develop.container.optIn' | translate }}
        </button>
      </div>
      <div class="col-3 clsCenterImgCG">
        <img class="clsContainerGradient_Icon" src="{{ imgPath }}" />
      </div>
    </div>
  </div>
</div>
