import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, forkJoin } from 'rxjs';
import { RatingOverlayService } from 'src/app/modules/dashboard/services/rating-overlay/rating-overlay.service';

@Component({
  selector: 'app-rating-overlay.',
  templateUrl: './rating-overlay.component.html',
  styleUrls: ['./rating-overlay.component.scss'],
  animations: [
    trigger('fade', [
      transition('* => *', [
        animate(
          '0.3s',
          keyframes([
            style({ opacity: 0, right: '-400px', offset: 0 }),
            style({ opacity: 0.5, right: 0, offset: 0.5 }),
            style({ opacity: 1, offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class RatingOverlayComponent implements OnInit {
  enjoyText: string;
  happyText: string;
  sorryText: string;
  currentText: string;
  enchancmentUrl: string;
  ratingUrl: string;

  @ViewChild('overlayText') overlayDOM: ElementRef;

  constructor(
    private translateService: TranslateService,
    private ratingOverlayService: RatingOverlayService,
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.getOverlayUrls();
  }

  getTranslations() {
    forkJoin([
      this.translateService.get('component.overlay.happy'),
      this.translateService.get('component.overlay.enjoy'),
      this.translateService.get('component.overlay.sorry'),
    ])
      .pipe(first())
      .subscribe(([happy, enjoy, sorry]) => {
        this.enjoyText = enjoy;
        this.happyText = happy;
        this.sorryText = sorry;
        this.currentText = enjoy;
      });
  }

  getSuccessMethod() {
    switch (this.currentText) {
      case this.enjoyText:
        return this.enjoySuccess();
      case this.happyText:
        return this.happySuccess();
      case this.sorryText:
        return this.sorrySuccess();
    }
  }

  getDeclineMethod() {
    switch (this.currentText) {
      case this.enjoyText:
        return this.enjoyDecline();
      case this.happyText:
        return this.happyDecline();
      case this.sorryText:
        return this.sorryDecline();
    }
  }

  enjoySuccess() {
    this.currentText = this.happyText;
  }

  happySuccess() {
    window.open(this.ratingUrl, '_blank');
    this.ratingOverlayService.openOverlay.next(false);
    localStorage.setItem('rating', 'true');
  }

  sorrySuccess() {
    window.open(this.enchancmentUrl, '_blank');
    this.ratingOverlayService.openOverlay.next(false);
    localStorage.setItem('rating', 'true');
  }

  enjoyDecline() {
    this.currentText = this.sorryText;
  }

  happyDecline() {
    this.ratingOverlayService.openOverlay.next(false);
    localStorage.setItem('rating', 'true');
  }

  sorryDecline() {
    this.ratingOverlayService.openOverlay.next(false);
    localStorage.setItem('rating', 'true');
  }

  getOverlayUrls() {
    this.ratingOverlayService
      .getOverlayUrls()
      .pipe(first())
      .subscribe(([rating, enchancment]) => {
        this.enchancmentUrl = enchancment.original_response;
        this.ratingUrl = rating.original_response;
      });
  }
}
