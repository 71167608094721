<div class="app-whitelabel-develop">
  <div class="col-12 app-whitelabel-develop__main">
    <img
      src="../../../assets/images/icons/folder-whitelabel-icon.svg"
      class="col-12 app-whitelabel-develop__main--icon"
    />
  </div>
  <div class="col-12 app-whitelabel-develop__title">
    {{ 'component.develop.whitelabel-app.title' | translate }}
  </div>
  <div class="col-12 app-whitelabel-develop__body">
    {{ 'component.develop.whitelabel-app.body' | translate }}
  </div>
  <div class="col-12 app-whitelabel-develop__link">
    <button
      class="app-whitelabel-develop__link--button"
      (click)="navigateToRelease()"
    >
      {{ 'component.develop.whitelabel-app.link' | translate | uppercase }}
      <img
      src="../../../assets/images/icons/arrow-right-icon.svg"
      class="clsBtnLinkExt"
    />
    </button>
  </div>
</div>
