import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { fork } from 'child_process';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { getAppFromUser } from 'src/app/Utils/helpers';

@Injectable({
  providedIn: 'root',
})
export class AuthResolver implements Resolve<Observable<any>> {
  constructor(private authService: AuthService) {}
  resolve(route: ActivatedRouteSnapshot) {
    return combineLatest([this.authService.user, this.authService.app]).pipe(
      take(1),
      map(([user, app]) => {
        const url = route.children.find((route) => route.params.app);
        if (!app && url) {
          return [user, getAppFromUser(url.params.app, user)];
        }
        return [user, app];
      }),
    );
  }
}
