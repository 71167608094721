<div class="row equal">
  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
    <div class="card container-fluid clsCardContainer" action="live_stats">
      <div *ngIf="reloadingKpi" class="card-body text-center">
        <img
          class="clsImgCenterVertical"
          src="../../../assets/images/icons/reload_big_pink.gif"
        />
      </div>
      <div
        *ngIf="!reloadingKpi || overallKpiObject"
        class="card-body clsBodyContent"
      >
        <div class="row">
          <div class="text-center col-xl-12 col-lg-12 col-md-4 col-sm-4 pt-2">
            <img alt="" src="../../../assets/images/icons/download-icon.svg" />
            <h3 class="mb-2 mt-2 clsKpi_Downloads clsDev_Main_Kpi">
              {{ overallKpiObject?.total_downloads | thousands: 2 }}
            </h3>
            <h6 class="clsDev_Main_Kpi_Text">
              {{ 'component.dashboard.laststate.total-downloads' | translate }}
            </h6>
          </div>
          <div
            class="
              text-center
              col-xl-12 col-lg-12 col-md-4 col-sm-4
              clsKpiSep
              pt-2
            "
          >
            <img alt="" src="../../../assets/images/icons/dau-icon.svg" />
            <h3 class="mt-2 mb-2 clsKpi_Dau clsDev_Main_Kpi">
              {{ overallKpiObject?.last_dau | thousands: 2 }}
            </h3>
            <h6
              class="clsDev_Main_Kpi_Text"
              title="{{
                'component.dashboard.kpi-graphic.dau-tooltip' | translate
              }}"
            >
              {{ 'component.dashboard.laststate.last-dau' | translate }}
            </h6>
          </div>
          <div
            class="
              text-center
              col-xl-12 col-lg-12 col-md-4 col-sm-4
              clsKpiSep
              pt-2
            "
          >
            <img alt="" src="../../../assets/images/icons/dau-icon.svg" />
            <h3 class="mt-2 mb-2 clsKpi_Mau clsDev_Main_Kpi">
              {{ overallKpiObject?.last_mau | thousands: 2 }}
            </h3>
            <h6
              class="clsDev_Main_Kpi_Text"
              title="{{
                'component.dashboard.kpi-graphic.mau-tooltip' | translate
              }}"
            >
              {{ 'component.dashboard.laststate.last-mau' | translate }}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="reloadingKpi || kpiObjectList"
    class="col-xl-9 col-lg-9 col-md-12 col-sm-12"
  >
    <app-kpi-graphic [kpiObjectList]="kpiObjectList"></app-kpi-graphic>
  </div>
</div>
