<div class="col-md-12" id="containersConfigure">
  <div class="row">
    <div class="col-md-12">
      <app-container-gradient
        [title]="containertitle"
        [subtitle]="containersubtitle"
        [repoUrl]="containerrepourl"
        [imgPath]="containerImgPath"
      ></app-container-gradient>
    </div>
  </div>

  <div class="row h-100 equal">
    <div class="col-6">
      <div
        *ngIf="checkIfVisible(globals.ACC_INAPP_MARKETING)"
        class="card container-fluid clsCardContainer"
        style="height: 91%"
        action="inapp_marketing"
      >
        <div class="card-body clsChangeInAppMarketing_Cnt">
          <div class="row">
            <div class="col-12 clsCard_Title">
              {{ 'component.engage.inappmark' | translate }}
            </div>
          </div>
          <div *ngIf="inApp !== ''" class="row clsChangeInAppMarketing_Det">
            <div class="col-md-12">&nbsp;</div>
          </div>
          <div class="clsCursorPointer clsChangeInAppMarketing">
            <div
              *ngIf="inApp === ''"
              class="row clsChangeInAppMarketing_Title"
              (click)="inAppChange('firebase')"
            >
              <div class="col-md-9 my-auto">
                <img
                  class="clsEngageImgApp"
                  src="../../../assets/images/logo_firebase.png"
                />
              </div>
              <div class="col-md-3 text-right my-auto">
                <img
                  class=""
                  src="../../../assets/images/icons/padlock_gray.svg"
                />
              </div>
            </div>
            <div *ngIf="inApp === 'firebase'" class="row">
              <div class="col-md-12 my-auto text-center">
                <img
                  class="clsEngageImgApp_Select"
                  src="../../../assets/images/logo_firebase.png"
                />
              </div>
            </div>
            <div *ngIf="inApp === 'firebase'" class="row text-center">
              <button
                class="ml-auto mr-auto clsSA_BtnGoToPlatform"
                (click)="
                  this.user.is_tour
                    ? this.service.optNotAvailable()
                    : goToConsole('firebase')
                "
              >
                {{ 'component.engage.go-console' | translate }}
                <img
                  class="clsBtnLinkExt"
                  src="../../../assets/images/icons/link_ext.png"
                />
              </button>
            </div>
            <div *ngIf="inApp === 'firebase'" class="row text-center">
              <button
                class="
                  ml-auto
                  mr-auto
                  clsSA_BtnGoToPlatform clsSA_BtnEngageImgApp
                "
                (click)="
                  this.user.is_tour
                    ? this.service.optNotAvailable()
                    : requestAccess('firebase')
                "
              >
                {{ 'component.engage.request-access' | translate }}
                <img
                  class="clsIconButtonRequestAccess"
                  src="../../../assets/images/icons/padlock.svg"
                />
              </button>
            </div>
          </div>
          <div
            *ngIf="inApp === ''"
            class="row clsChangeInAppMarketing_Det_Hide"
          >
            <div class="col-12">
              <hr class="clsSeparatorHR m-2 p-0 w-100" />
            </div>
          </div>
          <div class="clsCursorPointer clsChangeInAppMarketing">
            <div
              *ngIf="inApp === ''"
              class="row clsChangeInAppMarketing_Title"
              (click)="inAppChange('moengage')"
            >
              <div class="col-md-9 my-auto">
                <img
                  class="clsEngageImgApp"
                  src="../../../assets/images/logo_moengage.png"
                />
              </div>
              <div class="col-md-3 text-right my-auto">
                <img
                  class=""
                  src="../../../assets/images/icons/padlock_gray.svg"
                />
              </div>
            </div>
            <div *ngIf="inApp === 'moengage'" class="row">
              <div class="col-md-12 my-auto text-center">
                <img
                  class="clsEngageImgApp_Select"
                  src="../../../assets/images/logo_moengage.png"
                />
              </div>
            </div>
            <div *ngIf="inApp === 'moengage'" class="row text-center">
              <button
                class="ml-auto mr-auto clsSA_BtnGoToPlatform"
                (click)="
                  this.user.is_tour
                    ? this.service.optNotAvailable()
                    : goToConsole('moengage')
                "
              >
                {{ 'component.engage.go-console' | translate }}
                <img
                  class="clsBtnLinkExt"
                  src="../../../assets/images/icons/link_ext.png"
                />
              </button>
            </div>
            <div *ngIf="inApp === 'moengage'" class="row text-center">
              <button
                class="
                  ml-auto
                  mr-auto
                  clsSA_BtnGoToPlatform clsSA_BtnEngageImgApp
                "
                (click)="
                  this.user.is_tour
                    ? this.service.optNotAvailable()
                    : requestAccess('moengage')
                "
              >
                {{ 'component.engage.request-access' | translate }}
                <img
                  class="clsIconButtonRequestAccess"
                  src="../../../assets/images/icons/padlock.svg"
                />
              </button>
            </div>
          </div>
          <div
            *ngIf="inApp === ''"
            class="row clsChangeInAppMarketing_Det_Hide"
          >
            <div class="col-12">
              <hr class="clsSeparatorHR m-1 p-0 w-100" />
            </div>
          </div>
          <div class="clsCursorPointer clsChangeInAppMarketing">
            <div
              *ngIf="inApp === ''"
              class="row clsChangeInAppMarketing_Title"
              (click)="inAppChange('pushwoosh')"
            >
              <div class="col-md-9 my-auto">
                <img
                  class="clsEngageImgApp"
                  src="../../../assets/images/logo_pushwoosh.png"
                />
              </div>
              <div class="col-md-3 text-right my-auto">
                <img
                  class=""
                  src="../../../assets/images/icons/padlock_gray.svg"
                />
              </div>
            </div>
            <div *ngIf="inApp === 'pushwoosh'" class="row">
              <div class="col-md-12 my-auto text-center">
                <img
                  class="clsEngageImgApp_Select"
                  src="../../../assets/images/logo_pushwoosh.png"
                />
              </div>
            </div>
            <div *ngIf="inApp === 'pushwoosh'" class="row text-center">
              <button
                class="ml-auto mr-auto clsSA_BtnGoToPlatform"
                (click)="
                  this.user.is_tour
                    ? this.service.optNotAvailable()
                    : goToConsole('pushwoosh')
                "
              >
                {{ 'component.engage.go-console' | translate }}
                <img
                  class="clsBtnLinkExt"
                  src="../../../assets/images/icons/link_ext.png"
                />
              </button>
            </div>
            <div *ngIf="inApp === 'pushwoosh'" class="row text-center">
              <button
                class="
                  ml-auto
                  mr-auto
                  clsSA_BtnGoToPlatform clsSA_BtnEngageImgApp
                "
                (click)="
                  this.user.is_tour
                    ? this.service.optNotAvailable()
                    : requestAccess('pushwoosh')
                "
              >
                {{ 'component.engage.request-access' | translate }}
                <img
                  class="clsIconButtonRequestAccess"
                  src="../../../assets/images/icons/padlock.svg"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div
        *ngIf="checkIfVisible(globals.ACC_REPLY_TO_REVIEWS)"
        class="card container-fluid clsCardContainer"
        style="height: 91%"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-12 clsCard_Title">
              {{ 'component.engage.reply-reviews' | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 clsPaddingButton20">&nbsp;</div>
          </div>

          <div class="row">
            <div class="col-md-12 clsSA_IconTitle text-center">
              <img src="../../../assets/images/icons/monitor_grafic.svg" />
            </div>
          </div>
          <div class="row text-center">
            <button
              class="ml-auto mr-auto clsSA_BtnGoToPlatform"
              (click)="
                this.user.is_tour
                  ? this.service.optNotAvailable()
                  : replyToReviewsClick()
              "
            >
              {{ 'component.engage.go-platform' | translate }}
              <img
                class="clsBtnLinkExt"
                src="../../../assets/images/icons/link_ext.png"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
