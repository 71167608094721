<div class="card container-fluid clsCardContainer app-status-overview">
  <div *ngIf="!commitList || !appVersions" class="text-center">
    <img src="../../../../assets/images/icons/reload_big_pink.gif" />
  </div>
  <div test-id="app-status" *ngIf="commitList && appVersions" class="card-body">
    <div class="d-md-flex align-items-center">
      <div>
        <h4 class="clsCard_Title">
          {{ 'component.dashboard.app-status-overview.title' | translate }}
        </h4>
      </div>
    </div>
    <div class="status-gate">
      <div class="quality">
        <img
          class="clsIcon60x60 clsDev_CriticalData_Img"
          [src]="imageQualityGate"
        />
        <span
          >{{
            'component.dashboard.app-status-overview.quality-title' | translate
          }}
          <br /><b>{{ feedbackMessage }}</b></span
        >
      </div>
      <button
        class="clsCardButton wide"
        (click)="goToAppDistribution()"
        *ngIf="this.commitList.length > 0"
      >
        {{
          'component.dashboard.app-status-overview.create-version' | translate
        }}
      </button>
    </div>
    <div class="source-table" *ngIf="this.commitList.length > 0">
      <div class="title">
        <p>
          <b>{{
            'component.dashboard.app-status-overview.branch' | translate
          }}</b>
        </p>
        <p>{{ commitList[0].branch | dateAgo }}</p>
      </div>

      <div class="date">
        <p>
          <b>{{
            'component.dashboard.app-status-overview.date' | translate
          }}</b>
        </p>
        <p title="{{ commitList[0].commitDate | date: 'dd-MMM-yy HH:mm' }}">
          {{ commitList[0].commitDate | dateAgo }}
        </p>
      </div>

      <div class="author">
        <p>
          <b>{{
            'component.dashboard.app-status-overview.author' | translate
          }}</b>
        </p>
        <p>{{ commitList[0].author }}</p>
      </div>

      <div class="description">
        <p>
          <b>
            {{
              'component.dashboard.app-status-overview.description' | translate
            }}
          </b>
        </p>
        <p>{{ commitList[0].displayId }}: {{ commitList[0].commitComment }}</p>
      </div>
    </div>

    <div class="status-bars" *ngIf="this.appVersions.length">
      <div class="onboard">
        <div class="section"></div>
        <div class="section">
          <div class="title">
            <img
              src="../../../../assets/images/icons/checklist.png"
              alt="checklist"
            />
            <p>
              {{
                'component.dashboard.app-status-overview.release' | translate
              }}
            </p>
            <p>
              {{
                'component.dashboard.app-status-overview.candidate' | translate
              }}
            </p>
          </div>
        </div>
        <div class="section">
          <div class="title">
            <img
              src="../../../../assets/images/icons/shield.png"
              alt="shield"
            />
            <p>
              {{
                'component.dashboard.app-status-overview.security' | translate
              }}
            </p>
          </div>
        </div>
        <div class="section">
          <div class="title">
            <img
              src="../../../../assets/images/icons/skyrocket.png"
              alt="skyrocket"
            />
            <p>
              {{
                'component.dashboard.app-status-overview.publish' | translate
              }}
            </p>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let item of appVersions | slice: 0:3">
        <div class="version-container">
          <div class="version-col">
            <p class="version">v{{ item.version }}</p>
            <p class="created">
              {{
                'component.dashboard.app-status-overview.created-on' | translate
              }}
            </p>
            <p class="date">{{ item.created_at | date: 'dd-MMM-yy' }}</p>
          </div>
          <div class="status-col">
            <img
              *ngIf="updateStatusImage(item, 'distribution') !== ''"
              [title]="toolTipMessage(item, 'distribution')"
              class="image-status image-status_distribution"
              [ngClass]="showLoading(item, 'distribution')"
              [src]="updateStatusImage(item, 'distribution')"
              alt="success"
            />
          </div>
          <div class="status-col">
            <img
              *ngIf="updateStatusImage(item, 'security') !== ''"
              [title]="toolTipMessage(item, 'security')"
              class="image-status image-status_security"
              [ngClass]="showLoading(item, 'security')"
              [src]="updateStatusImage(item, 'security')"
              alt="success"
            />
          </div>
          <div class="status-col">
            <img
              *ngIf="updateStatusImage(item, 'request_publication') !== ''"
              [title]="toolTipMessage(item, 'request_publication')"
              class="image-status image-status_publish"
              [ngClass]="showLoading(item, 'request_publication')"
              [src]="updateStatusImage(item, 'request_publication')"
              alt="success"
            />
            <span *ngIf="showStatusBar(item)" class="date-publish">
              {{ item.published_at | date: 'dd-MMM-yy' }}
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
