<div
  id="delete-language"
  dlgid="dialog-delete-language"
  class="delete-language modal-body break-word"
>
  <div class="card container-fluid">
    <div class="row clsDeteleteMessage">
      <h1 class="title">
        {{ 'component.security-assessment.close' | translate }}
      </h1>
      <h1 class="clsTargetName">
        <div [innerHTML]="markItem(data.targetText + ' ?')"></div>
      </h1>
    </div>

    <div style="justify-content: center" class="row">
      <button class="clsCardButton" (click)="cancel()">
        <span class="button-text">{{ 'common.no' | translate }}</span>
      </button>
      <button class="clsCardButton focus-button" (click)="accept()">
        <span class="button-text">{{ 'common.yes' | translate }}</span>
      </button>
    </div>
  </div>
</div>
