import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LiveVersionSecurityBannerData } from '../shared/models/liveVersionSecurityBannerData';

@Injectable({
  providedIn: 'root',
})
export class GlobalsService {
  private readonly liveVersionSecurityBannerDataSource =
    new BehaviorSubject<LiveVersionSecurityBannerData>(null);
  liveVersionSecurityBannerData$ =
    this.liveVersionSecurityBannerDataSource.asObservable();

  constructor() {}

  setLiveVersionSecurityBannerData(value: LiveVersionSecurityBannerData) {
    this.liveVersionSecurityBannerDataSource.next(value);
  }
}
