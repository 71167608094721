import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/model/user';
import { Globals } from 'src/app/Utils/globals';
import { v4 as uuidv4 } from 'uuid';
import {
  SecurityFunctionalChecksService,
  UploadEvidenceHeaders,
} from '../../security/services/security-functional-checks.service';

@Component({
  selector: 'app-upload-evidence',
  templateUrl: './upload-evidence.component.html',
  styleUrls: ['./upload-evidence.component.scss'],
})
export class UploadEvidenceComponent {
  @Input() appUuid: string;
  @Input() evidenceId: string;
  @Input() info: string;
  @Output() emitEvidence: EventEmitter<string> = new EventEmitter<string>();
  file: File = null;
  mode = 'options';
  archerInfoUrl =
    'http://thenest-eur-hq.nestle.com/GL/GL_GLSC/grc/Pages/Archer.aspx';
  archerStatus = '';
  archerId = '';
  archer = null;
  currentDate: Date;
  searchCallState: string;
  submitCallState: string;
  user: User;
  maxSizeChunk: number = 100 * 1000 * 1000;

  constructor(
    public globals: Globals,
    private service: SecurityFunctionalChecksService,
    private activatedRoute: ActivatedRoute,
  ) {
    [this.user] = activatedRoute.snapshot.data.authData;
  }

  uploadFile(event) {
    const newFile = event[0];
    if (!!this.checkExtension(newFile)) {
      this.file = newFile;
      this.currentDate = new Date();
    }
  }

  checkExtension(newFile) {
    return true;
  }

  closeUploadFile() {
    this.submitCallState = this.globals.state_loading;

    this.service.uploadFileEvidenceUrl().subscribe({
      next: (res) => {
        this.uploadFileEvidence(res.upload_url);
      },
      error: (error) => {
        this.service.apiError(error.status);
        this.submitCallState = this.globals.state_success;
      },
    });
  }

  uploadFileEvidence(url: string) {
    const headers: UploadEvidenceHeaders = {
      headers: {
        'x-ms-meta-app_id': this.appUuid,
        'x-ms-meta-user_id': this.user?.authenticated_user,
        'x-ms-meta-functional_check_id': this.evidenceId,
        'x-ms-meta-file_name': this.file.name.toLowerCase(),
      },
    };
    if (this.file.size <= this.maxSizeChunk) {
      this.service.uploadFileEvidence(url, this.file, headers).subscribe({
        next: () => {
          this.submitCallState = this.globals.state_success;
          this.emitEvidence.emit('file');
        },
        error: (error) => {
          this.service.apiError(error.status);
          this.submitCallState = this.globals.state_success;
        },
      });
    } else {
      this.uploadSliceFileEvidence(url, headers);
    }
  }
  uploadSliceFileEvidence(url: string, headers: UploadEvidenceHeaders) {
    const lChunks = this.service.sliceFile(this.file, this.maxSizeChunk);
    const lUUID = uuidv4();
    const lID = window.btoa(lUUID);
    const chunkIndex = 0;
    const lXml =
      '<?xml version="1.0" encoding="utf-8"?><BlockList><Latest>' +
      lID +
      '</Latest>';

    this.filePutBlock(
      lChunks,
      url,
      lChunks[chunkIndex],
      lID,
      chunkIndex,
      lXml,
      headers,
    );
  }

  filePutBlock(pChunks, pUrl, pFile, pID, pIndex, pXml, pHeader): void {
    this.service.uploadFilePutBlock(pUrl, pFile, pID).subscribe({
      next: () => {
        const lUUID = uuidv4();
        const lID = window.btoa(lUUID);

        if (pIndex < pChunks.length - 1) {
          pIndex++;
          pXml += '<Latest>' + lID + '</Latest>';
          this.filePutBlock(
            pChunks,
            pUrl,
            pChunks[pIndex],
            lID,
            pIndex,
            pXml,
            pHeader,
          );
        } else if (pIndex === pChunks.length - 1) {
          pXml += '</BlockList>';
          this.putBlockList(pUrl, pXml, pHeader);
        }
      },
      error: (error) => {
        this.service.apiError(error.status);
        this.submitCallState = this.globals.state_success;
      },
    });
  }

  putBlockList(url, xml, pHeader): void {
    this.service.uploadFilePutBlockList(url, xml, pHeader).subscribe({
      next: () => {
        this.submitCallState = this.globals.state_success;
        this.emitEvidence.emit('file');
      },
      error: (error) => {
        this.service.apiError(error.status);
        this.submitCallState = this.globals.state_success;
      },
    });
  }

  removeFile(): void {
    this.file = null;
    this.archerStatus = '';
    this.archerId = '';
  }

  archerInputChanged() {
    if (!this.archerId) {
      this.archer = null;
      this.archerStatus = '';
    }
  }

  searchRisk(): void {
    this.searchCallState = this.globals.state_loading;
    this.archerStatus = '';
    this.service.validateRiskEvidence(this.archerId).subscribe({
      next: (data) => {
        this.searchCallState = this.globals.state_success;
        if (data) {
          this.archerStatus = data.isValid ? 'valid' : 'invalid';
          this.archer = data;
        }
      },
      error: (data) => {
        this.searchCallState = this.globals.state_success;
        this.archerStatus = 'invalid';
        this.archer = { validationMessage: data.error.error };
      },
    });
  }

  submitRisk(): void {
    this.submitCallState = this.globals.state_loading;
    this.service
      .uploadArcherEvidence(this.appUuid, this.evidenceId, this.archerId)
      .subscribe({
        next: (data) => {
          this.submitCallState = this.globals.state_success;
          this.emitEvidence.emit('archer');
        },
        error: (error) => {
          this.submitCallState = this.globals.state_success;
        },
      });
  }
}
