import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectResolver implements Resolve<Observable<any>> {
  constructor(private authService: AuthService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this.authService.app.pipe(
      map((app) => {
        this.router.navigate([`${app.uuid}/dashboard`]);
        return true;
      }),
    );
  }
}
