import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends CommonService {
  public getNotifications() {
    return this.httpClient.get(environment.urls.apiBaseUrl + 'notifications', {
      withCredentials: true,
    });
  }

  public notificationRead(notificationId: string) {
    return this.httpClient.put<any>(
      environment.urls.apiBaseUrl + `notifications/${notificationId}/read`,
      { withCredentials: true },
    );
  }
  public allNotificationsRead() {
    return this.httpClient.put<any>(
      environment.urls.apiBaseUrl + `notifications/read`,
      { withCredentials: true },
    );
  }
}
