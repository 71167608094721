<mat-button-toggle-group [(ngModel)]="initValue">
  <mat-button-toggle
    class = "app-toggle-button-left"
    [value]="option1Value"
    (change)="onChange($event.value)">
    {{ option1Text }}
  </mat-button-toggle>
  <mat-button-toggle
    class = "app-toggle-button-right"
    [value]="option2Value"
    (change)="onChange($event.value)">
    {{ option2Text }}
  </mat-button-toggle>
</mat-button-toggle-group>