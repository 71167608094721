<div id="warning-modal" dlgid="warning-modal" class="warning-modal">
  <div class="card container-fluid">
    <div class="text-container">
      <h1 class="title">
        {{ 'component.publish.metadata.warning' | translate }}
      </h1>
      <p>
        {{ 'component.publish.metadata.changes' | translate }}
      </p>
    </div>
    <div class="row justify-content-center">
      <button class="clsCardButton cancel-button" (click)="closeModal()">
        {{ 'component.publish.metadata.no' | translate }}
      </button>
      <button class="clsCardButton" (click)="continue()">
        {{ 'component.publish.metadata.yes' | translate }}
      </button>
    </div>
  </div>
</div>
