import { Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  text: string;
  subtext: string;
  subtext2: string;
  btntext: string;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.text = data.texts.text;
    this.subtext = data.texts.subtext;
    this.subtext2 = data.texts.subtext2;
    this.btntext = data.texts.btntext;
  }

  acceptBtn(): void {
    this.dialogRef.close();
  }
}
