<table
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="results_last_updated"
  matSortDirection="desc"
  class="mat-elevation-z0 no-wrap v-middle"
  [ngClass]="{ clsTableCollapse: collapsedSideBar === true }"
>
  <ng-container matColumnDef="aggregated_status">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="clsTblHeadStatus elipsis"
    >
      {{
        'component.security-assessment.security-findings.tbl-header-status'
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let item" class="clsValue elipsis">
      <span
        class="clsBadgeCmn findings"
        [ngClass]="{
          clsBadgeCmn_red2:
            item.aggregated_status === 'OPEN' ||
            item.aggregated_status === 'NEW',
          clsBadgeCmn_green:
            item.aggregated_status === 'CLOSED_FIXED' ||
            item.aggregated_status === 'CLOSED_ITEM_NOT_FOUND',
          clsBadgeCmn_grey:
            item.aggregated_status === 'CLOSED_PARTIALLY_FIXED' ||
            item.aggregated_status === 'CLOSED_RISK_ACCEPTED' ||
            item.aggregated_status === 'CLOSED_COMPENSATING_CONTROL' ||
            item.aggregated_status === 'OPEN_READY_TO_RESCAN' ||
            item.aggregated_status === 'CLOSED_BY_POLICY'
        }"
      >
        {{ item.aggregated_status | capitalLetter }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="clsTblHeadTitle elipsis"
    >
      {{
        'component.security-assessment.security-findings.tbl-header-title'
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let item" class="clsValue elipsis">
      <span title="{{ item.title | capitalLetter }}">{{
        item.title | capitalLetter
      }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="severity">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="clsTblHeadSeverity elipsis"
    >
      {{
        'component.security-assessment.security-findings.tbl-header-severity'
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let item" class="clsValue elipsis">
      <div
        class="clsGrid_dot clsGrid_dotSF_Status"
        [ngClass]="{
          clsDot_cranberry: item.severity === 'CRITICAL',
          clsDot_critical: item.severity === 'HIGH',
          clsDot_medium: item.severity === 'MEDIUM',
          clsDot_minor: item.severity === 'LOW'
        }"
      ></div>
      <span *ngIf="item.severity === 'LOW'">{{
        'component.security-assessment.security-findings.severity-low'
          | translate
      }}</span>
      <span *ngIf="item.severity === 'MEDIUM'">{{
        'component.security-assessment.security-findings.severity-medium'
          | translate
      }}</span>
      <span *ngIf="item.severity === 'HIGH'">{{
        'component.security-assessment.security-findings.severity-high'
          | translate
      }}</span>
      <span *ngIf="item.severity === 'CRITICAL'">{{
        'component.security-assessment.security-findings.severity-critical'
          | translate
      }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="results_last_updated">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="clsTblHeadDate elipsis"
    >
      {{
        'component.security-assessment.security-findings.tbl-header-date'
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let item" class="clsValue elipsis">
      {{ item.results_last_updated | date: 'dd-MMM-yy' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="view" sticky>
    <th mat-header-cell *matHeaderCellDef class="clsTblHeadView">
      {{
        'component.security-assessment.security-findings.tbl-header-view'
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let item" class="clsValue elipsis">
      <img
        [src]="item.rldIcn ? item.rldIcn : magnifyingIcon"
        width="30"
        height="30"
        alt=""
        class="clsCursorPointer detailFinding"
        (click)="this.openFindingDetailDialog(item, item.id)"
      />
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="tblCols"></tr>
  <tr mat-row *matRowDef="let row; columns: tblCols"></tr>
</table>
