import { PermissionInfo } from './permission.model';

export class Application {
  id?: number;
  package: string;
  tech: string;
  name: string;
  digipi?: number;
  permissions?: PermissionInfo;
  requester?: string;
  country?: string;
  icon: string;
  live: boolean;
  native: boolean;
  last_commit?: string;
  hybrid_framework?: string;
  uuid?: string;
  source_code_type: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
