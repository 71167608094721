<div class="archer-submit-modal">
  <div class="archer-submit-modal__main-image">
    <img [src]="statusImage()" alt="security-icon" width="100" height="100" />
  </div>
  <div class="archer-submit-modal__title">
    {{ statusTitle() | translate }}
  </div>
  <div class="archer-submit-modal__body">
    {{ statusBody() | translate }}
  </div>
  <div class="archer-submit-modal__footer">
    <button
      class="archer-submit-modal__footer--button"
      (click)="onCloseDialog()"
    >
      {{ 'common.close' | translate }}
    </button>
  </div>
</div>
