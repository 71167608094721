<div class="notificationModal">
  <div class="notificationModal__section--topside">
    <div class="notificationModal__row--top">
      <img
        class="notificationModal__row--top--icon"
        aria-hidden="false"
        aria-label="close icon"
        (click)="closeDialog()"
        src="assets/images/icons/close-icon-white.svg"
      />
    </div>
    <div class="notificationModal__row--title">
      <div
        class="notificationModal__row--title--text"
        [innerHTML]="notification.title | markdown"
      ></div>
      <img
        class="notificationModal__row--title--icon"
        src="assets/images/icons/plattform-icon.svg"
      />
    </div>
  </div>
  <div class="notificationModal__section--lower">
    <div class="notificationModal__row--body">
      <span
        class="notificationModal__row--body--text"
        [innerHTML]="notification.message | markdown"
      >
      </span>
    </div>
    <div class="notificationModal__row--bottom">
      <button (click)="closeDialog()" class="notificationModal__row--button">
        {{ 'component.notifications.got-it' | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
