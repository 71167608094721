<div class="warning-modal">
  <div class="container-fluid">
    <div class="text-container">
      <div *ngIf="type === 'warning'" class="text-container__img">
        <img src="../../../assets/images/icons/warning-icon.svg" />
      </div>
      <div *ngIf="type === 'info'" class="text-container__img">
        <img src="../../../assets/images/icons/info-icon-blue.svg" />
      </div>
      <div *ngIf="type === 'success'" class="text-container__img">
        <img src="../../../assets/images/icons/success-icon.svg" />
      </div>
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="advise">
        <p>{{ text }}</p>
      </div>
      <div class="advise">
        <p>{{ subtext }}</p>
      </div>
    </div>
    <div *ngIf="type === 'warning'" class="row footer">
      <button class="clsCardButton cancel-button" (click)="closeModal()">
        {{ 'common.cancel' | translate }}
      </button>
      <button class="clsCardButton" (click)="continue()">
        {{ 'common.continue' | translate }}
      </button>
    </div>
    <div *ngIf="type === 'info' || type === 'success'" class="row footer">
      <button class="clsCardButton cancel-button" (click)="closeModal()">
        {{ 'common.ok' | translate }}
      </button>
    </div>
  </div>
</div>
