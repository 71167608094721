import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalsService } from 'src/app/services/globals.service';
import { LiveVersionSecurityBannerData } from 'src/app/shared/models/liveVersionSecurityBannerData';

@Component({
  selector: 'app-live-version-security-issues-banner',
  templateUrl: './live-version-security-issues-banner.component.html',
  styleUrls: ['./live-version-security-issues-banner.component.css'],
})
export class LiveVersionSecurityIssuesBannerComponent implements OnInit {
  liveVersionSecurityBannerData: LiveVersionSecurityBannerData;

  constructor(
    private readonly globalsService: GlobalsService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.globalsService.liveVersionSecurityBannerData$.subscribe(
      (res) => (this.liveVersionSecurityBannerData = res),
    );
  }

  navigate() {
    this.router.navigate(['./dashboard']);
  }
}
