import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification } from 'src/app/model/notification';
import { AppStatusService } from 'src/app/modules/dashboard/services/app-status.service';
import { NotificationsService } from 'src/app/services/notifications.service';

interface Tab {
  title: string;
  category: string;
  notificationsNumber?: number;
}

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent implements OnInit {
  constructor(
    private service: NotificationsService,
    private dialogRef: MatDialogRef<NotificationCenterComponent>,
    private appStatus: AppStatusService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.notificationsList = data.notificationsList;
  }

  tabs: Tab[] = [
    { title: 'Your Apps', category: 'Application' },
    {
      title: 'Platform updates',
      category: 'Platform',
    },
  ];
  activeTab = this.tabs[0];

  notificationsList: Notification[] = [];

  updateNotificationsEmitter = new EventEmitter();

  ngOnInit(): void {
    this.setupTabsConfiguration();
  }

  setupTabsConfiguration() {
    const notificationTypes = this.tabs.map((tab) => tab.category);
    notificationTypes.forEach((category) =>
      this.unreadNotificationsCount(category),
    );
  }

  unreadNotificationsCount(category: string) {
    const notificationCategoryList = this.notificationsList.filter(
      (notification) => notification.category === category,
    );
    const foundCategoryTab = this.tabs.find((tab) => tab.category === category);
    const unreadCategoryTabNotifications = notificationCategoryList.filter(
      (notification) => !notification.read,
    );
    foundCategoryTab.notificationsNumber =
      unreadCategoryTabNotifications.length;
  }

  onNotificationClick(notification: Notification): void {
    if (notification.read === false) {
      this.service.notificationRead(notification.id).subscribe(() => {
        const foundNotification = this.notificationsList.find(
          (_notification) => _notification.id === notification.id,
        );
        foundNotification.read = true;
        this.updateNotifications();
      });
    }

    if (notification.link) {
      if (notification.link.includes('http')) {
        window.open(notification.link, '_blank');
      } else {
        this.appStatus.sectionNavigate.next({
          link: notification.link,
          package: notification.package,
          tech: notification.tech,
        });
        this.dialogRef.close(null);
      }
    }
  }

  onMarkAllAsRead() {
    this.service.allNotificationsRead().subscribe(() => {
      this.tabs.forEach((tab) => (tab.notificationsNumber = 0));
      this.notificationsList.forEach(
        (notification) => (notification.read = true),
      );
      this.updateNotifications();
    });
  }

  updateNotifications() {
    this.updateNotificationsEmitter.emit();
    this.setupTabsConfiguration();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }
}
