import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-create-modal',
  templateUrl: './warning-create-modal.component.html',
  styleUrls: ['./warning-create-modal.component.scss'],
})
export class WarningCreateModalComponent {
  type: string;
  title: string;
  text: string;
  subtext: string;
  constructor(
    public dialogRef: MatDialogRef<WarningCreateModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.type = data.type;
    this.title = data.title;
    this.text = data.text;
    this.subtext = data.subText;
  }

  public closeModal() {
    this.dialogRef.close('close');
  }

  public continue() {
    this.dialogRef.close();
  }
}
