import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppFeedbackModalData } from './appFeedbackModalData';

@Component({
  selector: 'app-app-feedback-modal',
  templateUrl: './app-feedback-modal.component.html',
  styleUrls: ['./app-feedback-modal.component.scss'],
})
export class AppFeedbackModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AppFeedbackModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AppFeedbackModalData,
  ) {}

  defineIconImage() {
    switch (this.data.type) {
      case 'info':
        return 'assets/images/icons/info.svg';
      case 'error':
        return 'assets/images/icons/error.svg';
    }
  }

  onButtonClick() {
    this.dialogRef.close();
  }
}
