import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  map,
  of,
  ReplaySubject,
  Subject,
  tap,
} from 'rxjs';
import { Action } from 'src/app/model/action.model';
import { Application } from 'src/app/model/application.model';
import { ResponseObject } from 'src/app/model/response-object.model';
import { Section } from 'src/app/model/section.model';
import { User } from 'src/app/model/user';
import { Globals } from 'src/app/Utils/globals';
import { getAppFromUser } from 'src/app/Utils/helpers';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  app: BehaviorSubject<Application> = new BehaviorSubject<Application>(null);
  globals: Globals;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private commonService: CommonService,
    globals: Globals,
  ) {
    this.globals = globals;
  }

  public getAppsForUser() {
    if (this.user.value) {
      return this.getUserFromSubject();
    }
    return this.httpClient
      .get(environment.urls.apiBaseUrl + 'cross/appsforuser')
      .pipe(
        map((res: ResponseObject) => {
          return this.mapResponse(res, false);
        }),
        catchError((error) => this.router.navigate(['/'])),
      );
  }

  public getAppsForTourUser() {
    return this.httpClient
      .get(environment.urls.apiBaseUrl + 'cross/getsampleapps')
      .pipe(
        map((res: ResponseObject) => {
          return this.mapResponse(res, true);
        }),
      );
  }

  private mapResponse(res, isTour?) {
    const user = this.parseUserAppsResponse(res.original_response, isTour);
    user.is_tour = isTour;
    this.user.next(user);
    this.app.next(this.loadDefaultApp());
    return user;
  }

  loadDefaultApp() {
    if (this.user.value != null) {
      const urlApp = getAppFromUser(
        window.location.href.split('/')[3],
        this.user.value,
      );
      if (urlApp) {
        return urlApp;
      } else {
        const lObjAppSelected = this.commonService.getDataLocalstorage();
        if (lObjAppSelected == null) {
          return this.user.value.applications[0];
        } else {
          return this.commonService.loadAppSelected(
            this.user.value.applications,
            lObjAppSelected,
          );
        }
      }
    }
  }

  private getUserFromSubject() {
    return this.user;
  }

  //Parse from Object to Instances
  private parseUserAppsResponse(
    originalResponseString: string,
    isTour: boolean,
  ): User {
    const user: User = new User();
    //assign to user class
    Object.assign(user, JSON.parse(originalResponseString));
    //assign the rest of classes inside user
    const applications: Array<Application> = new Array<Application>();
    user.applications.forEach((itemApp) => {
      //When its tour, we inject all perissions
      if (isTour) {
        itemApp.permissions = this.globals.tour_all_sections;
      }
      const app: Application = new Application(itemApp);
      const sectionsList: Array<Section> = new Array<Section>();
      itemApp.permissions.sections.forEach((itemSection) => {
        const section: Section = new Section(itemSection);
        const actions: Array<Action> = new Array<Action>();
        itemSection.actions.forEach((itemAction) => {
          const action: Action = new Action(itemAction);
          actions.push(action);
        });
        section.actions = actions;
        sectionsList.push(section);
      });
      app.permissions.sections = sectionsList;
      applications.push(app);
    });
    user.applications = applications;
    return user;
  }
}
