import { Application } from './application.model';

export class User {
  can_onboard: boolean;
  pending_onboards: boolean;
  applications: Array<Application>;
  is_tour: boolean = false;
  authenticated_user: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
