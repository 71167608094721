<div
  id="divContentChart"
  (click)="load()"
  class="card container-fluid clsCardContainer"
  style="height: 91%"
>
  <div *ngIf="!kpiObjectList" class="card-body text-center">
    <img
      alt=""
      class="clsImgCenterVertical"
      src="../../../assets/images/icons/reload_big_pink.gif"
    />
  </div>
  <div
    *ngIf="kpiObjectList"
    class="card-body clsBodyContent"
    style="visibility: visible; opacity: 1"
  >
    <div class="d-md-flex align-items-center">
      <div></div>
      <div class="ml-auto d-flex no-block align-items-center">
        <ul class="list-inline font-12 dl mr-3 mb-0 clsLegendGrafic">
          <fa-icon class="iconDau" [icon]="faCircle"> </fa-icon
          ><span
            class="text-dau"
            title="{{
              'component.dashboard.kpi-graphic.dau-tooltip' | translate
            }}"
            >{{ 'component.dashboard.kpi-graphic.dau' | translate }}</span
          >
          <fa-icon class="iconWau" [icon]="faCircle"></fa-icon>
          <span
            class="text-dau"
            title="{{
              'component.dashboard.kpi-graphic.wau-tooltip' | translate
            }}"
            >{{ 'component.dashboard.kpi-graphic.wau' | translate }}</span
          >
          <fa-icon class="iconMau" [icon]="faCircle"></fa-icon>
          <span
            class="text-dau"
            title="{{
              'component.dashboard.kpi-graphic.mau-tooltip' | translate
            }}"
            >{{ 'component.dashboard.kpi-graphic.mau' | translate }}</span
          >
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 clsChart">
        <div id="line-area" class="height-350 lineArea">
          <x-chartist [data]="data" [type]="type" [options]="options">
          </x-chartist>
        </div>
      </div>
    </div>
  </div>
</div>
