<div [ngClass]="{ 'app-button-disabled': disabled }" class="app-button">
  <button
    [ngClass]="{ submit: submitType }"
    (click)="handleClick()"
    [disabled]="disabled"
  >
    <img *ngIf="icon" [src]="icon" />
    <p>{{ text }}</p>
  </button>
</div>
