import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialog.service';

const IOSkey = 'ios';
const IOSFile = '.ipa';
const AndroidFile = '.aab';
const IOSSymbol = '.zip';
const AndroidSymbol = '.txt';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  @Input() tech: string;
  @Input() appType: string;
  @Output() emitFile: EventEmitter<File[]> = new EventEmitter<File[]>();
  file: File;
  symbol: File;
  aabFileIsInvalid: boolean;
  symbolFileIsInvalid: boolean;
  invalidFileNameCharacter: string;

  constructor(
    private objMatDialog: MatDialog,
    private translate: TranslateService,
  ) {}

  uploadFile(event) {
    this.aabFileIsInvalid = false;
    const newFile: File = event[0];
    this.invalidFileNameCharacter = this.containsForbiddenCharacters(
      newFile.name,
    );
    if (!this.checkFileExtension(newFile) || !!this.invalidFileNameCharacter) {
      this.aabFileIsInvalid = true;
      return;
    }
    this.file = newFile;
  }

  uploadSymbol(event) {
    this.symbolFileIsInvalid = false;
    const newFile: File = event[0];
    this.invalidFileNameCharacter = this.containsForbiddenCharacters(
      newFile.name,
    );
    if (
      !this.checkSymbolExtension(newFile) ||
      !!this.invalidFileNameCharacter
    ) {
      this.symbolFileIsInvalid = true;
      return;
    }

    this.symbol = newFile;
  }

  containsForbiddenCharacters(input: string) {
    const forbiddenCharacters = ['/', '\\', '?', '*', '|', '"', '<', '>', ':'];
    for (const char of input) {
      if (forbiddenCharacters.includes(char)) {
        return char;
      }
    }
    return null;
  }

  defineUploadFileErrorMessage(symbolFile: boolean) {
    const extension = symbolFile ? this.getSymbolType() : this.getFileType();
    const data = this.invalidFileNameCharacter
      ? { character: this.invalidFileNameCharacter }
      : { extension };
    return this.translate.instant(
      this.invalidFileNameCharacter
        ? 'component.common.upload-file-forbidden-character-error-message'
        : 'component.common.upload-file-extension-error-message',
      data,
    );
  }

  private checkFileExtension(file: File) {
    return file?.name?.toLowerCase().endsWith(this.getFileType());
  }

  private checkSymbolExtension(file: File) {
    return file?.name?.toLowerCase().endsWith(this.getSymbolType());
  }

  defineFileHeaderText() {
    return this.getSymbolType() == AndroidSymbol
      ? 'component.common.android-symbol-file-header'
      : 'component.appdistribution.create-version.symbol-file-header';
  }

  uploadBothFile() {
    this.emitFile.emit([this.file, this.symbol]);
  }

  getFileType(): string {
    return this.tech === IOSkey ? IOSFile : AndroidFile;
  }

  getSymbolType(): string {
    return this.tech === IOSkey ? IOSSymbol : AndroidSymbol;
  }

  resetFile() {
    this.file = undefined;
  }

  resetSymbol() {
    this.symbol = undefined;
  }

  disableSubmitBtn() {
    return !this.file;
  }

  private openAPKInvalidModal() {
    this.objMatDialog.closeAll();
    const objDialog: DialogService = new DialogService(this.objMatDialog);

    objDialog.icon = {
      src: '../../../assets/images/icons/warning-icon.svg',
      class: 'clsIcon80x80',
    };

    objDialog.texts = {
      title: this.translate.instant('component.common.upload-file-modal.title'),
      main: this.translate.instant(
        'component.common.upload-file-modal.description',
      ),
      subtext: '',
    };

    objDialog.buttons = [
      {
        btnText: 'OK',
        btnFunc: (pThisDlg: any) => {
          pThisDlg.close();
        },
      },
    ];

    objDialog.openDialog();
  }

  isIosApp() {
    return this.tech === IOSkey;
  }

  defineHelperMessage() {
    return this.translate.instant(
      this.isIosApp()
        ? 'component.common.upload-file-ios-helper-message'
        : 'component.common.upload-file-android-helper-message',
    );
  }
}
