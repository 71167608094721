import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable } from 'rxjs';
import { jsonResponseApi } from 'src/app/model/json-response.model';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { CreateVersionService } from 'src/app/modules/distribution/services/create-version.service';
import { AppChangeService } from 'src/app/services/app-change.service';
import { Globals } from 'src/app/Utils/globals';
import { environment } from 'src/environments/environment';
import { Application } from './model/application.model';
import { Section } from './model/section.model';
import { User } from './model/user';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { AuthService } from './services/auth/auth.service';
import { CommonService } from './services/common.service';
import { GlobalsService } from './services/globals.service';
import { LiveVersionSecurityBannerData } from './shared/models/liveVersionSecurityBannerData';

// gtag function from index.html
declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Globals],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly commonService: CommonService,
    private readonly location: Location,
    public globals: Globals,
    public _router: Router,
    private readonly translate: TranslateService,
    public servDash: DashboardService,
    private readonly activeRoute: ActivatedRoute,
    private readonly appChangeService: AppChangeService,
    private readonly createVersion: CreateVersionService,
    private readonly activateRoute: ActivatedRoute,
    private readonly globalsService: GlobalsService,
    private renderer: Renderer2,
  ) {
    this.loading = true;
  }

  title = 'Lighthouse';
  user: User;
  selectedApp: Application;
  selectedSection: Section;
  notificationsList: any;
  loading = false;
  isTour = false;
  appsUser: Observable<User>;
  urlSection: string;

  //todo, dinamizar esto en función de tamaño de pantalla para tablet en el init()
  collapsedSideBar = false;
  liveVersionSecurityBannerData: LiveVersionSecurityBannerData;

  ngOnInit() {
    this.addWalkmeUrl();
    this.getUserData();
    if (environment.production) {
      this.googleAnalyticsTracking();
    }
    this.onResizeAction();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    this.globalsService.liveVersionSecurityBannerData$.subscribe(
      (res) => (this.liveVersionSecurityBannerData = res),
    );
  }

  addWalkmeUrl() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (function() {
        var walkme = document.createElement('script');
        walkme.type = 'text/javascript';
        walkme.async = true;
        walkme.src = '${environment.walkmeSnippetUrl}';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(walkme, s);
        window._walkmeConfig = {smartLoad: true};
      })();`;
    this.renderer.appendChild(document.head, script);
  }

  fnClickleft(): void {
    const dash = new DashboardComponent(
      this.globals,
      this.servDash,
      this.authService,
      this.appChangeService,
      this.createVersion,
      this.activateRoute,
    );

    dash.reloadChart();
    setTimeout(function () {
      dash.reloadChart();
    }, 300);
  }

  getUserData() {
    this.authService.user
      .pipe(filter((user) => user !== null))
      .subscribe((user) => {
        this.user = user;
        this.getAppsForUser();
      });
  }

  getAppsForUser() {
    // TODO: the app must redirect/navigate by resolvers or guards instead of function below
    this.getAppsAndNavigate();

    this.setApplication(this.authService.loadDefaultApp());
  }

  private getAppsAndNavigate() {
    if (this._router.url === '/main/onboarding' && !this.user.can_onboard) {
      this._router.navigate(['/dashboard']);
    }
    if (
      this.user.applications.length === 0 &&
      this.user.can_onboard &&
      !this.isTour
    ) {
      if (this._router.url !== '/main/onboarding') {
        const navigationExtras: NavigationExtras = {
          state: { user: this.user },
        };
        this._router.navigate(['landingnoapp'], navigationExtras);
      }
    } else if (
      this.user.applications.length === 0 &&
      !this.user.can_onboard &&
      !this.isTour
    ) {
      this.canNotOnBoardRedirection();
    }
  }

  private canNotOnBoardRedirection() {
    const confirmText = this.translate.instant('component.common.goServiceNow');
    if (confirm(confirmText)) {
      this.loading = true;
      this.commonService
        .getCrossFormUrl(null, 'LIGHTHOUSE_USER_ONBOARD')
        .subscribe({
          next: (response: jsonResponseApi) => {
            const lUrl = response.original_response;
            window.location.href = lUrl;
          },
          error: (error: HttpErrorResponse) => {
            this.commonService.apiError(error.status);
            this.loading = false;
          },
        });
    } else {
      this._router.navigate(['']);
    }
  }

  onSelectionChange(changeData): void {
    this.authService.app.next(changeData.app);
    this.commonService.setDataLocalstorage(changeData.app);
    // Added navigation to '/ to reload all content when changing application
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([
        `${changeData.app.uuid.toLowerCase()}/${changeData.section}`,
      ]);
    });
  }

  setApplication(pObjAppSelected: Application): void {
    this.selectedApp = pObjAppSelected;
    this.commonService.setDataLocalstorage(pObjAppSelected);
    this.authService.app.next(this.selectedApp);
  }

  onSectionChange(pSection: Section): void {
    this.selectedSection = pSection;
  }

  onToogleSideBar(pbIsExpanded: boolean) {
    this.collapsedSideBar = pbIsExpanded;
    this.fnClickleft();
  }

  onResizeAction(): void {
    this.collapsedSideBar = !(window.innerWidth >= 1170);
  }

  googleAnalyticsTracking() {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', environment.googleAnalyticsId, {
          page_path: event.urlAfterRedirects,
        });
      });
  }
}
