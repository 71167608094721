<footer
  id="component-footer"
  class="footer text-center clsFooter"
  [ngClass]="{ clsFooterCollapsed: collapsedSideBar === true }"
>
  {{ sectionMessage }} {{ 'component.main-footer.follow-our' | translate }}
  <a class="clsContactSupport clsCursorPointer" (click)="redirectAnnouncements()">
    {{ 'component.main-footer.announcements' | translate }}
  </a>
  {{ 'component.main-footer.have-a-look' | translate }}
  <a class="clsContactSupport clsCursorPointer" (click)="redirectContact()">
    {{ 'component.main-footer.contact' | translate }}
  </a>
  {{ 'component.main-footer.or-if-needed' | translate }}
  <a class="clsContactSupport clsCursorPointer" (click)="redirectRaise()">
    {{ 'component.main-footer.raise' | translate }}
  </a>
</footer>
