<div *ngIf="liveVersionSecurityBannerData" class="security-issues-banner">
  <div>
    <img
      width="48px"
      height="40px"
      src="assets/images/icons/risk-white.svg"
      alt=""
    />
  </div>
  <div>
    <p>
      {{
        'component.live-version-security-issues-banner.header.first-part'
          | translate
      }}
      {{ liveVersionSecurityBannerData.securityIssuesCount }}
      {{
        'component.live-version-security-issues-banner.header.second-part'
          | translate
      }}
    </p>
    <p>
      {{ 'component.live-version-security-issues-banner.message' | translate }}
    </p>
  </div>
  <app-button
    (clickEvent)="navigate()"
    [text]="'component.live-version-security-issues-banner.button' | translate"
  ></app-button>
</div>
