<header class="topbar" data-navbarbg="skin1">
  <nav class="navbar top-navbar navbar-expand-md navbar-dark">
    <div
      class="navbar-collapse collapse"
      id="navbarSupportedContent"
      data-navbarbg="skin1"
    >
      <ul class="navbar-nav float-left mr-auto">
        <li class="nav-item">
          <a class="nav-link clsItemsMenuBold" href="">
            <img
              class="clsIconButtonGetStarted clsImgRotate180"
              src="../../../assets/images/icons/arror_right_lightblue.svg"
            />
            &nbsp;&nbsp;&nbsp;BACK&nbsp;TO&nbsp;HOME
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>
