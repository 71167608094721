import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    private route: Router,
    private objMatDialog: MatDialog,
    private translate: TranslateService,
  ) {}

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          this.route.navigate(['/']);
          this.showLogoutModal();
        }
        return throwError(() => error);
      }),
    );
  }

  private showLogoutModal() {
    this.objMatDialog.closeAll();
    const objDialog: DialogService = new DialogService(this.objMatDialog);

    objDialog.icon = {
      src: '../../../assets/images/icons/info.svg',
      class: 'clsIcon80x80',
    };

    objDialog.texts = {
      title: this.translate.instant('logout.title'),
      main: this.translate.instant('logout.description'),
      subtext: '',
    };

    objDialog.buttons = [
      {
        btnText: 'OK',
        btnFunc: function (pThisDlg: any) {
          pThisDlg.close();
        },
      },
    ];

    objDialog.openDialog();
  }
}
