<ng-container *ngIf="loading">
  <div *ngIf="loading" class="preloader">
    <div class="lds-ripple">
      <div class="lds-pos"></div>
      <div class="lds-pos"></div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!loading">
  <app-live-version-security-issues-banner></app-live-version-security-issues-banner>
  <router-outlet
    *ngIf="_router.url.includes('onboarding') || _router.url === '/'"
  ></router-outlet>

  <div
    *ngIf="
      !_router.url.includes('onboarding') && _router.url !== '/' && !loading
    "
    id="main-wrapper"
    class="clsToolbarMainWrapper clsNoToolbarVisible"
    data-theme="light"
    data-layout="vertical"
    data-navbarbg="skin1"
    data-sidebartype="full"
    data-sidebar-position="fixed"
    data-header-position="fixed"
    data-boxed-layout="full"
  >
    <app-tour-navbar *ngIf="user.is_tour"></app-tour-navbar>
    <app-notification-bell></app-notification-bell>
    <app-left-nav-bar
      (window:resize)="onResizeAction()"
      *ngIf="user"
      (changeSection)="onSectionChange($event)"
      (selectionChange)="onSelectionChange($event)"
      [userInfo]="user"
      (sideBarExpanded)="onToogleSideBar($event)"
      [ngClass]="{
        'left-sidebar-main': !collapsedSideBar,
        'left-sidebar-closed': collapsedSideBar,
        'tour-margin': user.is_tour,
        'liveAppHasSecurityIssuesTop': liveVersionSecurityBannerData !== null
      }"
    >
    </app-left-nav-bar>

    <div
      class="page-wrapper rightSection"
      [ngClass]="{
        rightSection: collapsedSideBar === false,
        'rightSection-closed': collapsedSideBar === true,
        'tour-margin': user.is_tour,
        'liveAppHasSecurityIssuesMarginTop': liveVersionSecurityBannerData !== null
      }"
      style="display: block"
    >
      <div id="divMainContent">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-main-footer
    *ngIf="
      _router.url !== '/main/onboarding' && _router.url !== '/' && !loading
    "
    [collapsedSideBar]="collapsedSideBar"
    [section]="selectedSection"
  ></app-main-footer>
</ng-container>
