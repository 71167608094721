import { Component, Input } from '@angular/core';
import { Globals } from 'src/app/Utils/globals';
import { Application } from 'src/app/model/application.model';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent {
  @Input() selectedApp: Application;
  @Input() user: User;

  constructor(public globals: Globals) {}

  sourceTypeBadge(): string {
    const sourceType = {
      Native: '../../../../assets/images/badge_native.svg',
      WhiteLabel: '../../../../assets/images/badge_whitelabel.svg',
      Hybrid: '../../../../assets/images/badge_hybrid.svg',
    };

    return sourceType[this.selectedApp.source_code_type];
  }
}
