<div class="clsNotificationsRow">
  <span class="clsNotificationCenterTitle">{{
    'component.notifications.notification-center' | translate
  }}</span>
  <img
    class="clsCloseIcon"
    aria-hidden="false"
    aria-label="close icon"
    (click)="onCloseDialog()"
    src="assets/images/icons/close-icon.svg"
  />
</div>

<nav mat-tab-nav-bar class="clsNav" [tabPanel]="tabPanel" disableRipple>
  <a
    *ngFor="let tab of tabs"
    mat-tab-link
    (click)="activeTab = tab"
    [active]="activeTab === tab"
  >
    <div>
      {{ tab.title }}
      <app-notification-dot
        class="clsNotificationDot"
        *ngIf="tab.notificationsNumber > 0"
        [notificationsNumber]="tab.notificationsNumber"
      ></app-notification-dot>
    </div>
  </a>

  <button class="clsMarkAllRead" (click)="onMarkAllAsRead()">
    {{ 'component.notifications.mark-all-as-read' | translate }}
  </button>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

<hr class="line" />
<div class="clsScrollbar">
  <div class="clsTab">
    <ng-container *ngFor="let notification of notificationsList">
      <div
        *ngIf="notification.category === activeTab.category"
        class="clsNotificationItem"
      >
        <app-notification-item
          (click)="onNotificationClick(notification)"
          [notification]="notification"
        ></app-notification-item>
      </div>
    </ng-container>
  </div>
</div>
