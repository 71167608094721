import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
//Translation
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TitleCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateService } from '@ngx-translate/core';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpRequestInterceptor } from './core/interceptors/HttpRequest.interceptor';
import { InterceptorService } from './core/interceptors/interceptor.service';
import { LeftNavBarComponent } from './modules/shared/left-nav-bar/left-nav-bar.component';
import { MainFooterComponent } from './modules/shared/main-footer/main-footer.component';
import { NotificationBellComponent } from './modules/shared/notification-bell/notification-bell.component';
import { NotificationCenterComponent } from './modules/shared/notification-bell/notification-center/notification-center.component';
import { SharedModule } from './modules/shared/shared.module';
import { TourNavbarComponent } from './modules/shared/tour-navbar/tour-navbar.component';
import { MappTranslateModule } from './shared/modules/mapp-translate.module';
import { Globals } from './Utils/globals';
import './shared/extensions/formGroupExtensions';

// function that returns `MarkedOptions` with renderer override
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.blockquote = (text: string) => {
    return '<blockquote class="blockquote"><p>' + text + '</p></blockquote>';
  };

  renderer.link = (href: string, title: string, text: string) => {
    return '<a href="' + href + '" target="_blank">' + text + '</a>';
  };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainFooterComponent,
    LeftNavBarComponent,
    TourNavbarComponent,
    NotificationBellComponent,
    NotificationCenterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MappTranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    FontAwesomeModule,
    MatDialogModule,
    NgSelectModule,
    FormsModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatCheckboxModule,
    ClipboardModule,
    MatDividerModule,
    FlexLayoutModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    Globals,
    TitleCasePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public static defaultLang = 'en-EN';

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang(AppModule.defaultLang);
  }
}
