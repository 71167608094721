import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-issues',
  templateUrl: './footer-issues.component.html',
  styleUrls: ['./footer-issues.component.scss'],
})
export class FooterIssuesComponent {
  constructor() {}
}
