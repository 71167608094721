import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from 'src/app/Utils/globals';
import { Application } from 'src/app/model/application.model';
import { SecurityFinding } from 'src/app/model/security-finding';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { LiveVersionSecurityBannerData } from 'src/app/shared/models/liveVersionSecurityBannerData';
import { SecurityFindingsService } from '../../security/services/security-findings.service';
import { GetLiveAppSecurityFindingsResponseDto } from '../models/getLiveAppSecurityFindingsResponseDto';

@Component({
  selector: 'app-live-version-status',
  templateUrl: './live-version-status.component.html',
  styleUrls: ['./live-version-status.component.css'],
})
export class LiveVersionStatusComponent implements OnInit {
  user: User;
  selectedApp: Application;
  liveAppSecurityFindingsResponse: GetLiveAppSecurityFindingsResponseDto;
  securityFindings: SecurityFinding[];
  liveVersionSecurityBannerData: LiveVersionSecurityBannerData;
  isLoading: boolean;
  tableFindingsColumns: string[] = [
    'aggregated_status',
    'title',
    'severity',
    'results_last_updated',
    'view',
  ];

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly securityFindingsService: SecurityFindingsService,
    private readonly globalsService: GlobalsService,
    private readonly translateService: TranslateService,
    private commonService: CommonService,
    public globals: Globals,
    private datePipe: DatePipe,
  ) {
    [this.user, this.selectedApp] = activatedRoute.snapshot.data.authData;
  }

  ngOnInit() {
    this.initComponent();
  }

  initComponent() {
    this.isLoading = true;
    this.globalsService.liveVersionSecurityBannerData$.subscribe(
      (res) => (this.liveVersionSecurityBannerData = res),
    );
    this.getLiveAppSecurityFindings();
  }

  getLiveAppSecurityFindings() {
    this.securityFindingsService
      .getLiveAppSecurityFindings(this.selectedApp.uuid)
      .subscribe({
        next: (res) => {
          this.liveAppSecurityFindingsResponse = res;
          this.securityFindings =
            this.liveAppSecurityFindingsResponse?.securityFindingDtos.map(
              (x) => {
                return {
                  title: x.title,
                  aggregated_status: x.aggregatedStatus,
                  results_last_updated: x.resultsLastUpdated,
                  severity: x.severity,
                  id: x.toolId,
                } as SecurityFinding;
              },
            );

          if (!this.isLiveSecurityBannerVisible()) {
            return;
          }

          const liveVersionSecurityBannerData: LiveVersionSecurityBannerData =
            res?.securityIssuesCount > 0
              ? {
                  securityIssuesCount: res?.securityIssuesCount,
                }
              : null;
          this.globalsService.setLiveVersionSecurityBannerData(
            liveVersionSecurityBannerData,
          );
        },
      })
      .add(() => (this.isLoading = false));
  }

  defineSecurityStatusAlert() {
    if (!this.hasSecurityIssues()) {
      return this.translateService.instant(
        'component.live-version-status.security-status-alert.secure',
      );
    }

    return `${
      this.liveAppSecurityFindingsResponse.securityIssuesCount
    } ${this.translateService.instant(
      'component.live-version-status.security-status-alert.not-secure',
    )}`;
  }

  defineSecurityStatusMessage() {
    if (!this.hasSecurityIssues()) {
      return this.translateService.instant(
        'component.live-version-status.security-status-message.secure',
      );
    }

    return `${
      this.liveAppSecurityFindingsResponse.securityIssuesCount
    } ${this.translateService.instant(
      'component.live-version-status.security-status-message.not-secure',
    )}`;
  }

  getFindingRouteOnClose() {
    return `${this.selectedApp.uuid}/dashboard`;
  }

  showAppTableFindings() {
    return (
      this.liveVersionSecurityBannerData ||
      this.liveAppSecurityFindingsResponse?.securityFindingDtos?.length > 0
    );
  }

  hasSecurityIssues() {
    return this.liveAppSecurityFindingsResponse?.securityIssuesCount > 0;
  }

  isLiveSecurityBannerVisible() {
    return this.commonService.isPortalActionVisible(
      this.selectedApp,
      this.globals.ACC_LIVE_SECURITY_BANNER,
      this.globals.DASHBOARD,
    );
  }

  defineLiveAppUpdateDate() {
    if (!this.liveAppSecurityFindingsResponse?.liveAppUpdateDate) {
      return 'N/A';
    }
    return this.datePipe.transform(
      this.liveAppSecurityFindingsResponse.liveAppUpdateDate,
      'dd-MMM-yy',
    );
  }
}
