import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { map, Observable, of, take } from 'rxjs';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DialogService } from 'src/app/services/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly objMatDialog: MatDialog,
    private readonly translate: TranslateService,
  ) {}

  user: User;
  isTour: boolean;
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    if (state.url === '/tour') {
      return this.authService.getAppsForTourUser().pipe(
        take(1),
        map((user) => {
          return true;
        }),
      );
    }

    if (state.url === '/') {
      return of(true);
    } else {
      return this.authService.getAppsForUser().pipe(
        take(1),
        map((user) => {
          if (!user) {
            this.router.navigate(['/']);
            this.showLogoutModal();
            return false;
          }
          return true;
        }),
      );
    }
  }

  private showLogoutModal() {
    this.objMatDialog.closeAll();
    const objDialog: DialogService = new DialogService(this.objMatDialog);

    objDialog.icon = {
      src: '../../../assets/images/icons/info.svg',
      class: 'clsIcon80x80',
    };

    objDialog.texts = {
      title: this.translate.instant('logout.title'),
      main: this.translate.instant('logout.description'),
      subtext: '',
    };

    objDialog.buttons = [
      {
        btnText: 'OK',
        btnFunc: function (pThisDlg: any) {
          pThisDlg.close();
        },
      },
    ];

    objDialog.openDialog();
  }
}
