import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  SecurityRiskAcceptance,
  SecurityTarget,
} from '../interfaces/security.interface';
import { SecurityFunctionalChecksService } from '../services/security-functional-checks.service';

@Component({
  selector: 'app-archer-risk-acceptance',
  templateUrl: './archer-risk-acceptance.component.html',
  styleUrls: ['./archer-risk-acceptance.component.scss'],
})
export class ArcherRiskAcceptanceComponent {
  @Input() archerInReview?: SecurityRiskAcceptance;
  @Input() uuid: string;
  @Input() securityFindingId: string;
  @Input() targets: SecurityTarget[];
  @Output() archerSubmitted = new EventEmitter();

  confirmationStep = false;
  submitting = false;
  archerId: string;

  constructor(private service: SecurityFunctionalChecksService) {}

  toggleStep() {
    this.confirmationStep = !this.confirmationStep;
  }

  goToConfirmation(id: string) {
    this.archerId = id;
    this.toggleStep();
  }

  uploadRiskAcceptance() {
    this.submitting = true;
    this.service
      .uploadRiskAcceptance(this.uuid, this.securityFindingId, this.archerId)
      .subscribe({
        next: () => {
          this.archerSubmitted.emit(true);
          this.submitting = false;
        },
        error: () => {
          this.archerSubmitted.emit(false);
          this.submitting = false;
        },
      });
  }
}
