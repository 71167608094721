import { Injectable } from '@angular/core';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { AnimationSteps } from './models/animation-steps.interface';
import {
  svgConfig,
  svgDevelop,
  svgApp,
  svgSecurity,
  svgTesting,
  svgPublish,
  svgEngage,
  svgMeasureLearn,
} from 'src/app/core/data/svg';

@Injectable({
  providedIn: 'root',
})
export class LandingService extends CommonService {
  animationStepsContent = {
    actualStep: 1,
    lastStep: 1,
    maxSteps: 11,
    lastScrollTS: new Date().getTime(),
    minTimeStep: 1450,
    scrollLineStep: 0,
    steps: [
      { id: 'landing ' },
      {
        id: 'develop',
        spans: [
          '<b>Develop</b> your app with centralized infrastructures,',
          'Continuous Integration toolchain',
        ],
        svg: svgDevelop,
      },
      {
        id: 'configure',
        spans: [
          '<b>Configure</b> and enable reusable and compliant-by-',
          'design cloud services and libraries (Push',
          'Notifications, beta testing, etc.)',
        ],
        svg: svgConfig,
      },
      {
        id: 'appdistribution',
        spans: [
          '<b>Share</b> and test your app with your internal stakeholders',
          'to verify and approve it for delivery',
        ],
        svg: svgApp,
      },
      {
        id: 'security',
        spans: [
          `Monitor constant and proactively your app's`,
          '<b>quality, security</b> and <b>robustness</b>',
        ],
        svg: svgSecurity,
      },
      {
        id: 'testing',
        spans: [
          `Ensure the best user experience by enabling full`,
          `<b>automated testing</b> coverage with a special focus`,
          `on connected devices.`,
        ],
        svg: svgTesting,
      },
      {
        id: 'publish',
        spans: [
          `<b>Publish.</b> Manage your app’s metadata and`,
          `localisation in an easy and transparent way.`,
        ],
        svg: svgPublish,
      },
      {
        id: 'engage',
        spans: [
          `<b>Engage.</b> Stay closer to our consumers through In-App marketing,`,
          `Push Notifications and Personalisation`,
        ],
        svg: svgEngage,
      },
      {
        id: 'measureLearn',
        spans: [
          `<b>Measure & Learn.</b> Make data-driven decisions`,
          `and get all performances' insights in one place.`,
        ],
        svg: svgMeasureLearn,
      },
      { id: 'takeatour' },
    ],
  };
  animationSteps: BehaviorSubject<AnimationSteps> =
    new BehaviorSubject<AnimationSteps>(this.animationStepsContent);

  resetAnimationSteps() {
    this.animationStepsContent.actualStep = 1;
    this.animationStepsContent.lastStep = 1;
    this.animationStepsContent.scrollLineStep = 0;
    this.animationSteps.next(this.animationStepsContent);
  }
}
