<nav
  class="navbar fixed-top navbar-expand-md navbar-light p-0 clsLayoutLH"
  id="mainNav"
>
  <a (click)="scrollToTop()" routerLink="./" class=""
    ><img
      class="clsLogoLH"
      src="../../../assets/images/lighthouse-logo.svg"
      id="layoutLogo"
  /></a>
  <button
    id="navbarButton"
    class="navbar-toggler visible"
    type="button"
    data-toggle="collapse"
    data-target="#navbarCollapse"
    aria-controls="navbarCollapse"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon my-toggler"></span>
  </button>
  <div
    *ngIf="_router.url !== '/landingnoapp'"
    class="collapse navbar-collapse visible"
    id="navbarCollapse"
  >
    <ul class="navbar-nav ml-md-auto">
      <li class="nav-item">
        <a
          class="nav-link clsNavItemsLayout"
          routerLink=""
          (click)="takeATour()"
          >{{
            'component.landing-page.navbar-landing.takeatour' | translate
          }}</a
        >
        <a></a>
      </li>
      <li class="nav-item clsSeparatorNavItem"></li>
      <li class="nav-item">
        <a class="nav-link clsButtonSignIn" href="{{ urlLogin }}">{{
          'component.landing-page.navbar-landing.sign-in' | translate
        }}</a>
      </li>
    </ul>
  </div>
</nav>
