<!-- MODAL -->
<div
  id="dialogAutoId_0_body"
  dlgid="dialogAutoId_0"
  class="modal-body break-word"
  style="padding: 0"
>
  <div id="dialogAutoId_0_body_content">
    <div class="container-fluid">
      <div class="row">
        <div *ngIf="!!data.head" class="col-12">
          {{ data.head }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <img
            width="70px"
            height="70px"
            alt=""
            class="clsLoading"
            src="../../../assets/images/icons/icons-loading-static.png"
          />
        </div>
      </div>
      <div *ngIf="data.subtext" class="col-12 clsModalText">
        {{ data.subtext }}
      </div>
    </div>
  </div>
</div>
