<div class="row toast-notification-container">
  <div class="col-2 toast-notification-container__image-container">
    <img src="{{ data.icon }}" class="app-image" />
  </div>
  <div class="col-10 toast-notification-container__content-container">
    <div class="content-header">
      <div class="col-8 content-header__container">
        <span class="title">{{ data.title }}</span>
        <img
          *ngIf="data.tech === 'android'"
          src="../../../assets/images/android.png"
          class="platform"
        />
        <img
          *ngIf="data.tech === 'ios'"
          src="../../../assets/images/ios.png"
          class="platform"
        />
      </div>
      <span class="col-4 content-header__date">{{
        data.timestamp | dateAgo
      }}</span>
    </div>
    <span class="content-msg">{{ data.message }}</span>
  </div>
</div>
