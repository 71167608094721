import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../../services/common.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends CommonService {
  /**
   * Recover latest changes
   * @params {package, technology}
   */
  public getDevelopLatestChanges(params: any) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'develop/latest_changes',
      params,
      { withCredentials: true },
    );
  }
  /**
   *
   * @param params
   */
  public getDevelopQualityGate(params: any) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'develop/qualitygate',
      params,
      { withCredentials: true },
    );
  }

  public getProjectStatus(appGuid: string) {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl + `apps/${appGuid}/quality/status`,
      { withCredentials: true },
    );
  }

  /**
   *
   * @param params
   */

  public getOverAllKpis(params: any) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'measure/overall_kpis',
      params,
      { withCredentials: true },
    );
  }

  public getDashboardLiveVersion(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'measure/live_app_version',
      params,
      { withCredentials: true },
    );
  }
  /**
   *
   * @param params
   */
  public getDashboardOverallKips(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'measure/overall_kpis',
      params,
      { withCredentials: true },
    );
  }

  /**
   *
   * @param params
   */
  public getDashboardOverallHistoricalKips(params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'measure/overall_kpis/historical',
      params,
      { withCredentials: true },
    );
  }

  /**
   *
   * @param params
   */

  public getNewsRelease(params) {
    return this.httpClient.get(
      environment.urls.apiBaseUrl + 'cross/getlatestreleasenote',
      {
        withCredentials: true,
      },
    );
  }

  public getAppVersions(params) {
    return this.httpClient.post(
      environment.urls.apiBaseUrl + 'communication/get_app_versions',
      params,
      { withCredentials: true },
    );
  }
}
