import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingService } from 'src/app/modules/landing-page/landing.service';
import { environment } from 'src/environments/environment';
import { AnimationSteps } from '../../landing-page/models/animation-steps.interface';

@Component({
  selector: 'app-navbar-landing',
  templateUrl: './navbar-landing.component.html',
  styleUrls: ['./navbar-landing.component.scss'],
})
export class NavbarLandingComponent implements OnInit {
  constructor(
    public _router: Router,
    public service: LandingService,
    private landingService: LandingService,
  ) {}

  isSticky: boolean = false;
  animationSteps: AnimationSteps;
  urlLogin: string;

  ngOnInit(): void {
    this.landingService.animationSteps.subscribe(
      (animationSteps: AnimationSteps) => {
        this.isSticky = animationSteps.actualStep >= 3;
        this.animationSteps = animationSteps;
      },
    );
    this.urlLogin = environment.urls.apiBaseUrl + 'login';
  }

  scrollTo(id: string) {
    this.animationSteps.actualStep = 11;
    this.landingService.animationSteps.next(this.animationSteps);
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
    });
  }

  scrollToTop() {
    this.landingService.resetAnimationSteps();
    this.landingService.scrollToTop();
  }

  takeATour(): void {
    (window.location.href = environment.urls.apiBaseUrl + 'login?op=tour'),
      '_blank';
  }
}
