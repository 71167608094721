import { Component, Inject } from '@angular/core';
import { Notification } from 'src/app/model/notification';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
@Component({
  selector: 'app-notification-toast',
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss'],
})
export class NotificationToastComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Notification) {}
}
