import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppTabElement } from './app-tab-element.interface';

@Component({
  selector: 'app-tabs',
  templateUrl: './app-tabs.component.html',
  styleUrls: ['./app-tabs.component.scss'],
})
export class AppTabsComponent {
  @Input() appTabElements: AppTabElement[];
  @Input() currentTab: number;
  @Output() tabSelect = new EventEmitter<number>();

  constructor() {}

  handleTabSelect(value: number) {
    this.currentTab = value;
    this.tabSelect.emit(this.currentTab);
  }
}
