import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-archer-submit-result-modal',
  templateUrl: './archer-submit-result-modal.component.html',
  styleUrls: ['./archer-submit-result-modal.component.scss'],
})
export class ArcherSubmitResultModalComponent {
  status: boolean;

  constructor(
    public dialogRef: MatDialogRef<ArcherSubmitResultModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.status = !!this.data.status;
  }

  statusImage() {
    return `../../../assets/images/icons/${
      this.status ? 'success' : 'warning'
    }-icon.svg`;
  }

  statusTitle() {
    return `component.security-assessment.security-findings.archer-submit-modal.${
      this.status ? 'success' : 'failure'
    }.title`;
  }

  statusBody() {
    return `component.security-assessment.security-findings.archer-submit-modal.${
      this.status ? 'success' : 'failure'
    }.body`;
  }

  onCloseDialog() {
    this.dialogRef.close();
  }
}
