import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dot',
  templateUrl: './app-dot.component.html',
  styleUrls: ['./app-dot.component.scss'],
})
export class AppDotComponent {
  @Input() type: 'success' | 'error' | 'warning';
  @Input() label: string;

  constructor() {}

  defineAppDotClass() {
    switch (this.type) {
      case 'success':
        return 'clsDot_dark_mint';
      case 'error':
        return 'clsDot_critical';
      case 'warning':
        return 'clsDot_medium';
    }
  }
}
