<div class="closeDialog" mat-dialog-close>
  <img
    (click)="closeModal()"
    class="clsImgCenterVertical"
    src="../../../assets/images/icons/decline-blue.svg"
  />
</div>
<div
  id="dialogAutoId_1_body"
  dlgid="dialogAutoId_1"
  class="modal-body break-word"
>
  <div id="dialogAutoId_1_body_content">
    <div class="clsDialogContent">
      <img
        class="clsIcon80x80"
        src="../../../assets/images/icons/success.svg"
      />
      <div class="clsSA_IconTitle">
        {{ text }}
      </div>
      <div class="clsSubtext">{{ subtext }}</div>
    </div>
  </div>
</div>
<div id="dialogAutoId_1_footer" class="modal-footer">
  <span
    id="dialogAutoId_1_btnCancel"
    (click)="closeModal()"
    class="clsBtnDlg"
    >{{ btntext }}</span
  >
</div>
