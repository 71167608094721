import { Component, Input } from '@angular/core';
import { Application } from 'src/app/model/application.model';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import { Globals } from 'src/app/Utils/globals';
import { Action } from 'src/app/model/action.model';
import { jsonResponseApi } from 'src/app/model/json-response.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-engage',
  templateUrl: './engage.component.html',
  styleUrls: ['./engage.component.scss'],
})
export class EngageComponent {
  constructor(
    public service: CommonService,
    public globals: Globals,
    private activatedRoute: ActivatedRoute,
  ) {
    [this.user, this.selectedApp] = activatedRoute.snapshot.data.authData;
  }

  selectedApp: Application;
  user: User;

  containertitle = 'component.engage.container.title';
  containersubtitle = 'component.engage.container.subtitle';
  containerImgPath: string = '../../../assets/images/icons/engage_big.svg';
  containerrepourl: string = null;

  inApp: any = '';

  inAppChange(selection): void {
    this.inApp = selection;
  }
  goToConsole(selection): void {
    if ((selection = 'firebase')) {
      //window.open("");
    } else if (selection == 'moengage') {
      //window.open("");
    } else if (selection == 'pushwoosh') {
      //window.open("");
    }
  }
  requestAccess(selection): void {
    if ((selection = 'firebase')) {
      //window.open("");
    } else if (selection == 'moengage') {
      //window.open("");
    } else if (selection == 'pushwoosh') {
      //window.open("");
    }
  }

  replyToReviewsClick(): void {
    const params = {
      package: this.selectedApp.package,
      technology: this.selectedApp.tech,
    };

    this.service
      .getCrossFormUrl(params, 'MEASURE_POWERBI_REGISTERED')
      .subscribe({
        next: (res: jsonResponseApi) => {
          const lUrl = res.original_response;
          window.open(lUrl);
        },
        error: (error) => {
          this.service.apiError(error.status);
        },
      });
  }

  checkIfVisible(currentAction: string): boolean {
    const action: Action = this.selectedApp.permissions.sections
      .find((section) => section.code === this.globals.ENGAGE)
      .actions.find((action) => action.code === currentAction);
    if (action != null) {
      return true;
    } else {
      return false;
    }
  }
}
