import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppTableRow } from './app-table-row.interface';

@Component({
  selector: 'app-table',
  templateUrl: './app-table.component.html',
  styleUrls: ['./app-table.component.scss'],
})
export class AppTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() appTableRows: AppTableRow[] = [];
  @Input() data: any[] = [];
  @Input() rowTemplate!: TemplateRef<any>;
  @Input() pageSizeOptions: number[] = [5];

  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const dataChanges = changes['data'];
    if (dataChanges && !dataChanges.firstChange) {
      this.initializeComponent();
      this.handleAfterViewInit();
      this.paginator?.firstPage();
    }
  }

  ngOnInit(): void {
    this.initializeComponent();
  }

  initializeComponent() {
    this.dataSource = new MatTableDataSource(this.data);
  }

  handleAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.handleAfterViewInit();
  }

  getDisplayedColumns() {
    return this.appTableRows.map((row) => row.columnName);
  }
}
