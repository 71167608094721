import {
  Component,
  Optional,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface RedirectData {
  text: string;
  clickWord: string;
  link: string;
}

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss'],
})
export class GeneralModalComponent {
  @Output() buttonSelected: EventEmitter<string> = new EventEmitter<string>();
  buttons: any[];
  selectedButton: string;
  title: string;
  main: string;
  mainSmall: string;
  subtext: string;
  icon: any;
  redirectData: RedirectData;

  constructor(
    public dialogRef: MatDialogRef<GeneralModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.buttons = data.buttons;
    this.title = data.texts.title;
    this.main = data.texts.main;
    this.mainSmall = data.texts.mainSmall;
    this.subtext = data.texts.subtext;
    this.icon = data.icon;
    this.redirectData = data.redirectData;
  }

  onCloseClick(): void {
    this.dialogRef.close(this.data);
  }
}
