import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - value) / 1000);
      if (seconds < 29) {
        // less than 30 seconds ago will show as 'Just now'
        return this.translate.instant('component.common.justnow');
      }
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };

      const intervalsString = {
        year: this.translate.instant('component.common.year'),
        month: this.translate.instant('component.common.month'),
        week: this.translate.instant('component.common.week'),
        day: this.translate.instant('component.common.day'),
        hour: this.translate.instant('component.common.hour'),
        minute: this.translate.instant('component.common.minute'),
        second: this.translate.instant('component.common.second'),
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            return (
              counter +
              ' ' +
              intervalsString[i] +
              ' ' +
              this.translate.instant('component.common.ago')
            ); // singular (1 day ago)
          } else {
            return (
              counter +
              ' ' +
              intervalsString[i] +
              this.translate.instant('component.common.s-ago')
            ); // plural (2 days ago)
          }
        }
      }
    }
    return value;
  }
}
