import {
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Application } from 'src/app/model/application.model';
import { User } from 'src/app/model/user';
import { SecurityFindingsService } from 'src/app/modules/security/services/security-findings.service';
import { Globals } from 'src/app/Utils/globals';
import { FindingDetailDialogComponent } from '../../finding-detail-dialog/finding-detail-dialog.component';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { SecurityFinding } from 'src/app/model/security-finding';
import { Subscription } from 'rxjs';
import { SecurityFindingResponse } from '../../interfaces/security.interface';

@Component({
  selector: 'app-table-findings',
  templateUrl: './table-findings.component.html',
  styleUrls: ['./table-findings.component.scss'],
})
export class TableFindingsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() user: User;
  @Input() selectedApp: Application;
  @Input() collapsedSideBar: boolean = false;
  @Input() tblCols: string[];
  @Input() tblData: SecurityFinding[];
  @Output() updateTable: EventEmitter<{}> = new EventEmitter<{}>();
  @Input() findingRouteOnClose: string;

  allSecurityFindings: any;
  filteredSecurityFindings: any;
  dataSource: any;
  idSecurityFinding: string;
  magnifyingIcon: string =
    '../../../assets/images/icons/magnifying_glass_detail.png';
  reloadIcon: string = '../../../assets/images/icons/reload.gif';
  iconView: string = '../../../assets/images/icons/magnifying_glass_detail.png';
  subscription: Subscription;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public service: SecurityFindingsService,
    public globals: Globals,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.getSecurityFinding();
    this.subscribeToUpdates();
    this.showOnlyPendingIssues();
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getSecurityFinding() {
    if (this.service.idSecurityFinding) {
      return;
    }

    this.service.idSecurityFinding = this.route.snapshot.paramMap.get('id');

    if (this.service.idSecurityFinding) {
      const paramsSecurity = {
        package: this.selectedApp.package,
        technology: this.selectedApp.tech,
        parameters: {
          app_security_id: this.service.idSecurityFinding,
        },
        psId: this.service.idSecurityFinding,
        uuid: this.selectedApp.uuid,
      };

      this.getSecurityFindingsDetail(
        paramsSecurity,
        this.service.idSecurityFinding,
      );
    }
  }

  openFindingDetailDialog(pItemSelected: any, psId: string): void {
    const params = {
      package: this.selectedApp.package,
      technology: this.selectedApp.tech,
      parameters: {
        app_security_id: psId,
      },
      psId,
      uuid: this.selectedApp.uuid,
    };

    pItemSelected.rldIcn = this.reloadIcon;
    this.getSecurityFindingsDetail(params, psId, pItemSelected);
  }

  private getSecurityFindingsDetail(
    params: any,
    psId: string,
    pItemSelected?: any,
  ) {
    if (this.user.is_tour) {
      this.service.optNotAvailable();
      pItemSelected.rldIcn = this.magnifyingIcon;
      return;
    }

    this.location.go(`${this.selectedApp.uuid}/security/${psId}`);
    this.service.getSecurityFindingsDetail(params).subscribe({
      next: (res: SecurityFindingResponse) => {
        if (res != null) {
          const data = {
            user: JSON.stringify(this.user),
            package: this.selectedApp.package,
            technology: this.selectedApp.tech,
            app_security_id: psId,
            result: this.mapDataFinding(res),
            uuid: this.selectedApp.uuid,
          };
          const dialogRef = this.dialog.open(FindingDetailDialogComponent, {
            width: '757px',
            height: '869px',
            panelClass: 'clsDialogView',
            data: data,
            disableClose: false,
          });
          if (pItemSelected) {
            pItemSelected.rldIcn = this.magnifyingIcon;
          }
          dialogRef.afterClosed().subscribe((reload) => {
            if (reload) {
              this.updateTable.emit();
            }
            const route =
              this.findingRouteOnClose ?? `${this.selectedApp.uuid}/security/`;
            this.location.go(route);
          });
        }
      },
      error: (error) => {
        this.service.apiError(error.status);
        if (pItemSelected) {
          pItemSelected.rldIcn = this.magnifyingIcon;
        }
      },
    });
  }

  mapDataFinding(data: SecurityFindingResponse) {
    return {
      title: data.title,
      description_intro: data.intro,
      security_finding_targets: data.targets,
      Description: data.description,
      recommendation: data.recommendation,
      secure_code: data.secure_code,
      notes: data.comments_tab,
      compliance_policy_references: data.regulatory_compliances,
      archer: data.risk_acceptance,
      environment: data.environment,
    };
  }

  subscribeToUpdates() {
    this.subscription = this.service.updateTableInfo$.subscribe((data: any) => {
      if (data.tblData) {
        this.tblData = data.tblData;
      }

      let updatedTable = this.tblData;

      if (!data.showClosedIssues) {
        updatedTable = this.filterByStatus();
      }

      updatedTable = updatedTable.filter((security: SecurityFinding) => {
        return security.severity === data.severity.value;
      });

      if (data.severity.value === 'ALL') {
        updatedTable = this.tblData;

        updatedTable = data.showClosedIssues
          ? this.tblData
          : this.filterByStatus();
      }

      this.dataSource = new MatTableDataSource(updatedTable);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  showOnlyPendingIssues() {
    if (this.tblData) {
      const updatedTable = this.filterByStatus();
      this.dataSource = new MatTableDataSource(updatedTable);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  filterByStatus(): SecurityFinding[] {
    if (this.tblData?.length === 0) {
      return [];
    }

    return this.tblData.filter((security: SecurityFinding) => {
      return (
        security.aggregated_status === 'NEW' ||
        security.aggregated_status === 'OPEN'
      );
    });
  }
}
