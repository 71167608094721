import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import { DialogService } from 'src/app/services/dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-container-gradient',
  templateUrl: './container-gradient.component.html',
  styleUrls: ['./container-gradient.component.scss'],
})
export class ContainerGradientComponent {
  constructor(
    public service: CommonService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public _router: Router,
  ) {}

  @Input() imgPath: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() repoUrl: string;
  @Input() user: User;
  @Input() howToWork: boolean;
  @Input() optIn: boolean;

  @Output() howToWorkEmmiter: EventEmitter<any> = new EventEmitter<any>();

  repositoryBtn(event: any): void {
    if (this.repoUrl.indexOf('error:') !== -1) {
      this.service.apiError(
        this.repoUrl.substring(this.repoUrl.lastIndexOf(':') + 1),
      );
    } else {
      this.openDialog(event);
    }
  }

  openDialog(event: any): void {
    const lThis = this;
    const lURL = this.repoUrl;
    const btnClicked = event.currentTarget.innerText;
    const objDialog: DialogService = new DialogService(this.dialog);

    objDialog.texts = this.loadTextDlg(btnClicked);
    objDialog.buttons = [
      {
        btnText: this.translate.instant('component.general-modal.cancel'),
        btnFunc: function (pThisDlg: any) {
          pThisDlg.close();
        },
      },
      {
        btnText: this.translate.instant(
          'component.develop.container.popup-btn',
        ),
        btnFunc: function (pThisDlg: any) {
          lThis.service.copyToClipboard(lURL);
          pThisDlg.close();
        },
      },
    ];

    objDialog.openDialog();
  }

  loadTextDlg(psBtnClicked: string) {
    const dlgText: any = {
      title: '',
      main: '',
      mainSmall: '',
      subtext: '',
      keyCrossFormUrl: '',
    };

    dlgText.title = this.translate.instant(
      'component.develop.container.popup-title',
    );
    dlgText.main = this.translate.instant(
      'component.develop.container.popup-subtitle',
    );
    dlgText.mainSmall = this.translate.instant(
      'component.develop.container.popup-subtitleSmall',
    );

    return dlgText;
  }

  public showHowToWork(): void {
    this.howToWorkEmmiter.emit(true);
  }
}
