import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousands',
})
export class ThousandsPipe implements PipeTransform {
  transform(input: number, args?: any): any {
    var exp,
      rounded,
      suffixes = ['K', 'M', 'G', 'T', 'P', 'E'];
    let res;
    if (input !== undefined && input !== null) {
      res = input;
      if (input > 1000) {
        exp = Math.floor(Math.log(input) / Math.log(1000));
        res = (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
      }
      return res;
    } else {
      return '';
    }
  }
}
