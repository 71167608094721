<div
[ngClass]="{
  'app-info-status-container-success': isSuccess
}"
class="app-info-status-container"
>
<div class="app-info-details" [ngClass]="{
  'compact': compactStyle}">
  <div class="app-info-detail" [ngClass]="{
    'compact': compactStyle}">
    <p>{{ versionTitle }}</p>
    <p>{{ versionValue }}</p>
  </div>
  <div class="app-info-detail" [ngClass]="{
      'compact': compactStyle}">
    <p>{{ dateTitle }}</p>
    <p>{{ dateValue }}</p>
  </div>
</div>
<div class="app-info-status">
  <div class="app-info-message-title">
    <img
      [src]="infoStatusImgPath"
      alt=""
    />
    <div>
      <p>{{ statusTitle }}</p>
      <p>{{ statusAlert }}</p>
    </div>
  </div>
  <div *ngIf="statusMessage" 
       class="app-info-message"
  >
    <p>{{ statusMessage }}</p>
  </div>
</div>
</div>