import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../../../services/common.service';

@Injectable({
  providedIn: 'root',
})
export class RatingOverlayService extends CommonService {
  openOverlay = new Subject<boolean>();

  public getOverlayUrls(): Observable<any> {
    return forkJoin([
      this.httpClient.post<any>(
        environment.urls.apiBaseUrl + 'cross/formurl/LIGHTHOUSE_PRODUCT_RATING',
        {},
      ),
      this.httpClient.post<any>(
        environment.urls.apiBaseUrl +
          'cross/formurl/LIGHTHOUSE_ENHANCEMENT_FORM',
        {},
      ),
    ]);
  }
}
