<!-- MODAL -->
<div
  id="dialogAutoId_0_body"
  dlgid="dialogAutoId_0"
  class="modal-body break-word"
  style="padding: 0"
>
  <div id="dialogAutoId_0_body_content">
    <div class="container-fluid">
      <div class="row">
        <div *ngIf="!data.head" class="col-12 clsTitleOnboardingProcess">
          {{ 'component.onboarding.loading-dialog.main' | translate }}
        </div>
        <div *ngIf="!!data.head" class="col-12 clsTitleOnboardingProcess">
          {{ data.head }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <img
            class="clsGifOnboardingProcess mx-auto d-block"
            src="../../../assets/images/lighthouse.gif"
          />
        </div>
      </div>
      <div class="row">
        <div *ngIf="!data.body" class="col-12 clsTextOnboardingProcess">
          {{ 'component.onboarding.loading-dialog.text1' | translate }}
        </div>
      </div>
      <div class="row">
        <div *ngIf="!data.body" class="col-12 clsFooterOnboardingProcess">
          {{ 'component.onboarding.loading-dialog.text2' | translate }}<br />{{
            'component.onboarding.loading-dialog.text3' | translate
          }}
        </div>
        <div *ngIf="!!data.body" class="col-12 clsFooterOnboardingProcess">
          {{ data.body }}<br />
        </div>
      </div>
    </div>
  </div>
</div>
