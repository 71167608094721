import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './app-toggle-button.component.html',
  styleUrls: ['./app-toggle-button.component.scss'],
})
export class AppToggleButtonComponent {
  @Input() option1Text: string = 'option 1';
  @Input() option2Text: string = 'option 2';
  @Input() option1Value: string = 'option1';
  @Input() option2Value: string = 'option2';
  @Input() initValue: string = 'option1';

  @Output() emitChange: EventEmitter<string> = new EventEmitter<string>();

  onChange(value: string): void {
    this.emitChange.emit(value);
  }

  constructor() {}
}
