<div class="card clsCardContainer live-version-status-container">
  <div *ngIf="isLoading" class="card-body text-center">
    <img
      alt=""
      class="clsImgCenterVertical"
      src="../../../assets/images/icons/reload_big_pink.gif"
    />
  </div>
  <div *ngIf="!isLoading">
    <h4 class="clsCard_Title">
      {{ 'component.live-version-status.title' | translate }}
    </h4>
    <div *ngIf="liveAppSecurityFindingsResponse">
      <app-info-status-card
        [isSuccess]="!hasSecurityIssues()"
        [versionTitle]="'component.live-version-status.app-version-detail.version' | translate"
        [versionValue]="liveAppSecurityFindingsResponse.liveAppVersion"
        [dateTitle]="'component.live-version-status.app-version-detail.updateDate' | translate"
        [dateValue]="defineLiveAppUpdateDate()"
        [statusTitle]="'component.live-version-status.app-version-status-title' | translate"
        [statusAlert]="defineSecurityStatusAlert()"
        [statusMessage]="defineSecurityStatusMessage()"
      >
      </app-info-status-card>
      <div
        *ngIf="showAppTableFindings()"
        class="table-responsive live-security-status-security-findings"
      >
        <app-table-findings
          class="clsFont16"
          [user]="user"
          [selectedApp]="selectedApp"
          [findingRouteOnClose]="getFindingRouteOnClose()"
          [tblCols]="tableFindingsColumns"
          [tblData]="securityFindings"
          [collapsedSideBar]="false"
          (updateTable)="initComponent()"
        ></app-table-findings>
      </div>
    </div>
    <div *ngIf="!liveAppSecurityFindingsResponse">
      <h5>{{ 'component.live-version-status.no-data' | translate }}</h5>
    </div>
  </div>
</div>
