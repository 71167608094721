import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import * as Chartist from 'chartist';
import { ChartEvent, ChartType } from 'ng-chartist';
import { KpiHistory } from 'src/app/model/kpi-history.model';
import { Chart } from '../chart';

@Component({
  selector: 'app-kpi-graphic',
  templateUrl: './kpi-graphic.component.html',
  styleUrls: ['./kpi-graphic.component.scss'],
})
export class KpiGraphicComponent implements OnInit, OnChanges, Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
  public charts: Chart[];
  faCircle = faCircle;
  loading: boolean = false;

  @Input() kpiObjectList: KpiHistory[];

  constructor() {}

  ngOnInit(): void {
    this.load();
  }
  ngOnChanges() {
    //TODO not to reload if we still have the values for the same app and same section
    this.load();
  }

  load(): void {
    this.loading = true;
    if (this.kpiObjectList != null) {
      this.data = this.loadData()['data'];
      this.options = this.loadOptions();
      this.type = 'Line';
    }
    this.loading = false;
  }

  loadOptions(): any {
    const labels = this.data.labels;
    const options = {
      showArea: true,
      height: 350,
      fullWidth: true,
      plugins: [
        //Chartist.plugins.tooltip()
      ],
      axisX: {
        showGrid: false,
        labelInterpolationFnc: function (value, index, chart) {
          if (value === labels[index - 1]) {
            return ' ';
          } else {
            return labels[index];
          }
        },
      },
      axisY: {
        showGrid: true,
        gridLines: {
          color: 'black',
          borderDash: [2, 5],
        },
      },
    };
    return options;
  }
  /** TODO Apply responsitivity when we implement that phase

  loadResponsiveOptions(){
    let lData = [];
    var responsiveOptions = [
      ['screen and (min-width: 1025px)', {
          axisX: {
              labelInterpolationFnc: function(value) {
                  // Will return Mon, Tue, Wed etc. on medium screens.
                  if (lData.indexOf(value) === -1) {
                      lData.push(value);
                      return value+"hola";
                  } else {
                      return null;
                  }
              }
          }
      }],
      ['screen and (min-width: 641px) and (max-width: 1024px)', {
          axisX: {labelInterpolationFnc: function(value) {
                  // Will return Mon, Tue, Wed etc. on medium screens.
                  if (lData.indexOf(value) === -1) {
                      lData.push(value);
                      return value;
                  } else {
                      return null;
                  }
              }
          }
      }],
      ['screen and (max-width: 640px)', {
          axisX: {
              labelInterpolationFnc: function(value) {
                  // Will return M, T, W etc. on small screens.
                  if (lData.indexOf(value) === -1) {
                      lData.push(value);
                      return value;
                  } else {
                      return null;
                  }
              }
          }
      }]
    ]
  }
 */

  loadData(): any {
    //fill labels
    const labels: any[] = new Array<any>();
    this.kpiObjectList.forEach((item) => {
      labels.push(this.convertToMonthYear(item.date));
    });
    //fill  Dau
    const dauSeries: Number[] = new Array<any>();
    this.kpiObjectList.forEach((item) => {
      dauSeries.push(Math.round(Number(item.last_dau)));
    });
    //fill Wau
    const wauSeries: Number[] = new Array<any>();
    this.kpiObjectList.forEach((item) => {
      wauSeries.push(Math.round(Number(item.last_mau)));
    });
    //fill Mau
    const mauSeries: Number[] = new Array<any>();
    this.kpiObjectList.forEach((item) => {
      mauSeries.push(Math.round(Number(item.last_wau)));
    });

    const data: any = {
      data: {
        labels: labels,
        series: [dauSeries, wauSeries, mauSeries],
      },
    };
    return data;
  }

  convertToMonthYear(pData: any) {
    var lDate = pData;
    if (typeof lDate !== 'object') {
      lDate = new Date(pData);
    }

    return this.convertToShortMonth(lDate) + ' ' + lDate.getFullYear();
  }

  convertToShortMonth(pData) {
    var shortMonths = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return shortMonths[pData.getMonth()];
  }
}
