import { Action } from './action.model';

export class Section {
  code: string;
  actions: Array<Action>;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
