import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Globals } from 'src/app/Utils/globals';
import { SecurityFunctionalChecksService } from '../../security/services/security-functional-checks.service';
import { Archer } from '../../security/interfaces/security.interface';

@Component({
  selector: 'app-risk-acceptance',
  templateUrl: './risk-acceptance.component.html',
  styleUrls: ['./risk-acceptance.component.scss'],
})
export class RiskAcceptanceComponent {
  @Input() appUuid: string;
  @Input() evidenceId: string;
  @Input() securityArcher = false;
  @Output() emitRisk = new EventEmitter<string>();
  @Output() emitSearchCallState = new EventEmitter<string>();
  @Output() emitSubmitCallState = new EventEmitter<string>();

  searchCallState: string;
  submitCallState: string;
  archerStatus = '';
  archerId = '';
  archer: Archer;
  archerInfoUrl =
    'http://thenest-eur-hq.nestle.com/GL/GL_GLSC/grc/Pages/Archer.aspx';

  constructor(
    public globals: Globals,
    private readonly service: SecurityFunctionalChecksService,
  ) {}

  archerInputChanged() {
    if (!this.archerId) {
      this.archer = null;
      this.archerStatus = '';
    }
  }

  searchRisk() {
    this.searchCallState = this.globals.state_loading;
    this.emitSearchCallState.emit(this.searchCallState);
    this.archerStatus = '';
    this.service.validateRiskEvidence(this.archerId).subscribe({
      next: (data) => {
        if (data) {
          this.archerStatus = data.isValid ? 'valid' : 'invalid';
          this.archer = data;
          this.archerId = this.archer.riskManagementId;
        }
        this.searchCallState = this.globals.state_success;
        this.emitSearchCallState.emit(this.searchCallState);
      },
      error: (responseError) => {
        this.archerStatus = 'invalid';
        this.archer = { validationMessage: responseError.error.error };
        this.searchCallState = this.globals.state_success;
        this.emitSearchCallState.emit(this.searchCallState);
      },
    });
  }

  submitRisk() {
    if (this.securityArcher) {
      this.emitRisk.emit(this.archer.riskManagementId);
      return;
    }
    this.submitCallState = this.globals.state_loading;
    this.emitSubmitCallState.emit(this.submitCallState);
    this.service
      .uploadArcherEvidence(this.appUuid, this.evidenceId, this.archerId)
      .subscribe({
        next: () => {
          this.emitRisk.emit('archer');
          this.submitCallState = this.globals.state_success;
          this.emitSubmitCallState.emit(this.submitCallState);
        },
        error: () => {
          this.submitCallState = this.globals.state_success;
          this.emitSubmitCallState.emit(this.submitCallState);
        },
      });
  }
}
