import appsettings from '../../appsettings.json';

export const environment = {
  production: false,
  title: 'Local Environment',
  googleAnalyticsId: 'UA-156319915-4',
  urls: {
    baseUrl: appsettings['MAPP-FRONTEND-BASE-URL'],
    apiBaseUrl: appsettings['MAPP-FRONTEND-SERVICES-BASE-URL'],
    signOut: appsettings['MAPP-FRONTEND-SIGN-OUT-URL'],
  },
  supportEmail: appsettings['SUPPORT-EMAILS'],
  walkmeSnippetUrl: appsettings['WALKME-SNIPPET-URL'],
  searchDigipiUrl:
    'https://nestle.service-now.com/digipi?id=search&spa=1&t=nestle-digipi-asset&q=',
};
