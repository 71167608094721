<div class="clsNotificationItemContainer">
  <div class="clsNotificationAppIconContainer">
    <img
      [src]="appIcon || DEFAULT_ICON"
      class="clsNotificationAppIcon"
      alt="app-icon"
    />
  </div>

  <div class="clsNotificationInfo">
    <div class="clsNotificationInfoTop">
      <div class="clsNotificationTitleSection">
        <span
          class="clsNotificationTitle"
          [innerHTML]="notification.title | markdown"
        ></span>

        <img [src]="techUrl" class="clsNotificationPlatformIcon" />
      </div>

      <div class="clsNotificationAgeSection">
        <span class="clsNotificationAgeText">{{
          notification.timestamp | dateAgo
        }}</span>

        <app-notification-dot
          class="clsNotificationDot"
          size="small"
          *ngIf="!notification.read"
        ></app-notification-dot>
      </div>
    </div>
    <div class="clsNotificationInfoBottom">
      <span [innerHTML]="notification.message | markdown"></span>
    </div>
  </div>
</div>
