<div class="clsCardModal">
  <div class="modal-body break-word">
    <div class="clsTitleRow">
      <div class="clsFD_TitleSection">
        <label 
          class="clsEnvironmentLabel"
          [attr.environment]="dataEnvironment.toLowerCase()"
        >
          {{ dataEnvironment }}
      </label>
        <label class="clsFD_Title">
          {{ dataTitle }}
        </label>
      </div>
      <div class="clsFD_CloseIcon">
        <img
          class="clsCloseIcon"
          aria-hidden="false"
          aria-label="close icon"
          (click)="onCloseDialog()"
          src="assets/images/icons/close-icon.svg"
        />
      </div>
    </div>
    <div class="clsBodyRow">
      <mat-tab-group disablePagination="true" animationDuration="0ms">
        <mat-tab label="Description">
          <div *ngIf="dataDescription">
            <div
              class="clsFD_SectionContent"
              [innerHTML]="dataDescription | markdown"
            ></div>
          </div>
          <div class="row m-0" *ngIf="dataCompliance">
            <div class="col-12 pt-5 p-0 clsFD_Section">
              {{
                'component.security-assessment.security-findings.detail.section-regulatory-compliance'
                  | translate
              }}
            </div>
          </div>
          <div class="row" *ngIf="dataCompliance">
            <div class="col-12 p-0 clsFD_SectionContent">
              <div class="clsRepositoryCompliance">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-11">
                      <div class="row">
                        <div class="col-12 clsRepositoryComplianceTitle">
                          {{ dataCompliance.compliance_policy }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 clsRepositoryComplianceText">
                          {{ dataCompliance.markdown }}
                        </div>
                      </div>
                    </div>
                    <div class="col-1 clsContentCenterVertically">
                      <img
                        class="clsCursorPointer"
                        src="../../../../assets/images/icons/link_ext_inactive.png"
                        width="15"
                        height="15"
                        alt=""
                        (click)="service.openLinkOnTab(dataCompliance.link)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Affected components">
          <div *ngIf="dataDescriptionIntro">
            <div
              class="clsFD_SectionContent"
              [innerHTML]="dataDescriptionIntro | markdown"
            ></div>
          </div>

          <div *ngIf="dataFindingTargets">
            <div
              class="clsFindingTargets"
              *ngFor="let item of dataFindingTargets; let i = index"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-8 clsNotesTitle">
                    <div
                      class="clsBadgeCmn col-4"
                      [ngClass]="getTargetBadgeStyle(item)"
                    >
                      {{ item.status | capitalLetter }}
                    </div>
                    <div
                      class="clsBadgeCmn clsBadgeCmn_blue ml-1 col-4"
                      *ngIf="item.self_close"
                    >
                      {{
                        'component.security-assessment.security-findings.detail.self-close'
                          | translate
                      }}
                    </div>
                  </div>
                  <div class="col-4 clsNotesDate">
                    {{ item.status_date | date: 'dd-MMM-yy' }}
                  </div>
                </div>
                <div class="row additional-contexts-container">
                  <div
                    class="col-8 clsFindingTargetText"
                    [innerHTML]="item.text | markdown"
                  ></div>
                  <div
                    *ngIf="showMoreContentArrow(item)"
                    class="clsCircle mt-3"
                    [class]="cssClass(item)"
                    (click)="checkMoreInfo(item)"
                  ></div>
                  <div
                    *ngIf="item.additional_contexts && item.seeMore"
                    class="mb-4 col-12"
                  >
                    <div class="additional-contexts" [class]="seeMore(item)">
                      <div *ngFor="let context of item.additional_contexts">
                        <div [innerHTML]="markItem(context.body)"></div>
                      </div>
                    </div>
                    <div *ngIf="item.self_close">
                      <div class="custom-checkbox">
                        <input
                          type="checkbox"
                          id="acceptRiskCheck"
                          class="custom-control-input clsCursorPointer"
                          [(ngModel)]="acceptRiskCheck"
                          [ngModelOptions]="{ standalone: true }"
                        />
                        <label
                          id="disclaimer"
                          class="custom-control-label clsCardChkLabel clsCursorPointer pl-1"
                          for="acceptRiskCheck"
                          [ngClass]="{ 'text-dark': acceptRiskCheck }"
                          >{{ item.self_close_message | translate }}</label
                        >
                      </div>
                      <div class="closeRiskButtonSection">
                        <button
                          type="button"
                          class="clsCardButtonActions clsCursorPointer"
                          [disabled]="disableCloseRiskButton(item)"
                          (click)="
                            acceptRisk(
                              item.text,
                              item.id,
                              item.can_self_close,
                              item.self_close_message
                            )
                          "
                        >
                          {{
                            item.status !== 'CLOSED_RISK_ACCEPTED'
                              ? 'ACCEPT RISK'
                              : 'RISK ACCEPTED'
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Recommendation">
          <div class="row m-0" *ngIf="dataRecommendation">
            <div
              class="col-12 p-0 clsFD_SectionContent"
              [innerHTML]="dataRecommendation | markdown"
            ></div>
          </div>
          <div class="row m-0" *ngIf="dataSecureCode">
            <div class="col-12 pt-5 p-0 clsFD_Section">
              {{
                'component.security-assessment.security-findings.detail.section-secure-code'
                  | translate
              }}
            </div>
          </div>
          <div class="row m-0" *ngIf="dataSecureCode">
            <div
              class="col-12 p-0 clsFD_SectionContent"
              [innerHTML]="dataSecureCode | markdown"
            ></div>
          </div>
        </mat-tab>

        <mat-tab label="Risk acceptance">
          <app-archer-risk-acceptance
            [archerInReview]="dataArcher"
            [uuid]="uuid"
            [securityFindingId]="app_security_id"
            [targets]="dataFindingTargets"
            (archerSubmitted)="submittedArcher($event)"
          ></app-archer-risk-acceptance>
        </mat-tab>

        <mat-tab label="Comments">
          <div class="row m-0">
            <div class="col-12 p-0 clsFD_Section">
              <div class="comment-type-options .clsFD_SectionContent">
                <div class="custom-checkbox">
                  <input
                    type="radio"
                    value="internal"
                    name="comment-type"
                    id="internal-comment"
                    class="custom-control-input clsCursorPointer"
                    [(ngModel)]="commentType"
                  />
                  <label
                    for="internal-comment"
                    class="custom-control-label custom-control-label-rounded custom-control-label-rounded__text-modal"
                  >
                    {{
                      'component.security-assessment.security-findings.detail.section-questions-comments.internal-note'
                        | translate
                    }}
                  </label>
                </div>
                <div class="custom-checkbox">
                  <input
                    type="radio"
                    value="security"
                    name="comment-type"
                    id="security-question"
                    class="custom-control-input clsCursorPointer"
                    [(ngModel)]="commentType"
                  />
                  <label
                    for="security-question"
                    class="custom-control-label custom-control-label-rounded custom-control-label-rounded__text-modal"
                  >
                    {{
                      'component.security-assessment.security-findings.detail.section-questions-comments.security-question'
                        | translate
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-12 p-0">
              <div *ngIf="blSendingPost === false" class="clsQuestionsComments">
                <textarea
                  [(ngModel)]="txtQuestionComment"
                  class="clsQuestionsCommentsTextArea"
                  placeholder="{{
                    'component.security-assessment.security-findings.detail.section-questions-comments.placeholder'
                      | translate
                  }}"
                  tabindex="-1"
                ></textarea>
                <div
                  class="clsQuestionsCommentsEnter clsCursorPointer"
                  [ngClass]="{
                    'clsCursorDefault clsDisabledEnter':
                      txtQuestionComment === ''
                  }"
                  (click)="
                    user.is_tour
                      ? service.optNotAvailable()
                      : txtQuestionComment !== ''
                      ? fnSendQuestionComment()
                      : false
                  "
                >
                  {{
                    'component.security-assessment.security-findings.detail.section-questions-comments.enter'
                      | translate
                  }}
                </div>
              </div>
              <div
                *ngIf="blSendingPost === true"
                class="clsQuestionsComments clsCenterImg"
              >
                <img
                  class="mx-auto"
                  src="../../../assets/images/icons/reload.gif"
                />
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="clsNotes" *ngFor="let item of dataNotes; let i = index">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-9 clsNotesTitle">
                    <span class="comment-author clsRobotoBold">{{
                      item.author_email
                    }}</span>
                    <span class="securityComment">
                      {{
                        'component.security-assessment.security-findings.detail.section-notes.commented'
                          | translate
                      }}
                    </span>
                    <span
                      *ngIf="item.is_internal_comment"
                      class="clsBadgeCmn comment-type iris"
                    >
                      {{
                        'component.security-assessment.security-findings.detail.section-questions-comments.internal-badge'
                          | translate
                      }}
                    </span>
                    <span
                      *ngIf="!item.is_internal_comment"
                      class="clsBadgeCmn comment-type pinkish"
                    >
                      {{
                        'component.security-assessment.security-findings.detail.section-questions-comments.security-badge'
                          | translate
                      }}
                    </span>
                  </div>
                  <div class="col-3 clsNotesDate">
                    {{ item.created_on | date: 'dd-MMM-yy h:mm a' | lowercase }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 clsNotesText">
                    {{ item.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="clsFooterRow">
      <button class="clsFooterButton" (click)="onCloseDialog()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </div>
</div>
