import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';
import { Notification } from 'src/app/model/notification';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent {
  notification: Notification;

  constructor(
    public dialogRef: MatDialogRef<GeneralModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.notification = data.notification;
  }

  closeDialog(): void {
    this.dialogRef.close(this.data.notification);
  }
}
