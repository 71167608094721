import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CommonService } from '../../../services/common.service';

@Injectable({
  providedIn: 'root',
})
export class AppStatusService extends CommonService {
  sectionNavigate = new Subject<any>();
  sectionNavigate$: Observable<any> = this.sectionNavigate.asObservable();
}
