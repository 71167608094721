<div class="helper-message">
  <p>{{ defineHelperMessage() }}</p>
</div>
<div class="d-flex dropdowns-container">
  <div class="w-100">
    <span class="file-header">{{
      'component.common.file-header' | translate: { fileType: getFileType() }
    }}</span>
    <div
      *ngIf="!file"
      class="uploadfilecontainer text-center mr-5"
      [ngClass]="{ 'upload-file-container-error': aabFileIsInvalid }"
      appDragDrop
      (FileDroppedEvent)="uploadFile($event)"
    >
      <div class="clsContent">
        <div class="clsDropText">
          {{ 'component.common.drop' | translate: { fileType: getFileType() } }}
        </div>
        <div>
          <span class="clsDropText">{{
            'component.common.or' | translate
          }}</span>
          <span class="clsDropTextLink" (click)="fileInput.click()">{{
            'component.common.browse-your-files' | translate
          }}</span>
        </div>
        <input
          hidden
          type="file"
          #fileInput
          (change)="uploadFile($event.target.files)"
        />
      </div>
    </div>
    <p *ngIf="aabFileIsInvalid" class="upload-file-container-error-message">
      {{ defineUploadFileErrorMessage(false) }}
    </p>
    <div *ngIf="file" class="mr-5">
      <div class="fileSelectedContainer">
        <img
          class="clsIconBtnReport mr-3"
          alt=""
          src="../../../assets/images/icons/report.svg"
          width="40"
          height="40"
        />
        <div class="fileSelectedName mr-1">{{ file.name }}</div>
        <div class="fileSelectedSize">- {{ file.size | fileSize }}</div>
        <img
          class="clsIconBtnReport clsCursorPointer ml-auto"
          alt=""
          src="../../../assets/images/icons/close-icon-red.svg"
          width="20"
          height="20"
          (click)="resetFile()"
        />
      </div>
    </div>
  </div>
<div class="w-100">
    <span class="file-header">{{
      defineFileHeaderText() | translate: { fileType: getSymbolType() }
    }}</span>
    <div
      *ngIf="!symbol"
      class="uploadfilecontainer text-center"
      [ngClass]="{
        'upload-file-container-error': symbolFileIsInvalid
      }"
      appDragDrop
      (FileDroppedEvent)="uploadSymbol($event)"
    >
      <div class="clsContent">
        <div class="clsDropText">
          {{
            'component.common.drop' | translate: { fileType: getSymbolType() }
          }}
        </div>
        <div>
          <span class="clsDropText">{{
            'component.common.or' | translate
          }}</span>
          <span class="clsDropTextLink" (click)="symbolInput.click()">{{
            'component.common.browse-your-files' | translate
          }}</span>
        </div>
        <input
          hidden
          type="file"
          #symbolInput
          (change)="uploadSymbol($event.target.files)"
        />
      </div>
    </div>
    <p *ngIf="symbolFileIsInvalid" class="upload-file-container-error-message">
      {{ defineUploadFileErrorMessage(true) }}
    </p>
    <div *ngIf="symbol">
      <div class="fileSelectedContainer">
        <img
          class="clsIconBtnReport mr-3"
          alt=""
          src="../../../assets/images/icons/report.svg"
          width="40"
          height="40"
        />
        <div class="fileSelectedName mr-1">{{ symbol.name }}</div>
        <div class="fileSelectedSize">- {{ symbol.size | fileSize }}</div>
        <img
          class="clsIconBtnReport clsCursorPointer ml-auto"
          alt=""
          src="../../../assets/images/icons/close-icon-red.svg"
          width="20"
          height="20"
          (click)="resetSymbol()"
        />
      </div>
    </div>
  </div> 
</div>
<div class="footer-container">
  <app-button
    text="{{ 'component.common.btnSubmit' | translate }}"
    [disabled]="disableSubmitBtn()"
    [submitType]="true"
    (clickEvent)="uploadBothFile()"
  ></app-button>
</div>
