export class Notification {
  id: string;
  link: string;
  message: string;
  package: string;
  read: boolean;
  tech: string;
  timestamp: number;
  title: string;
  icon: string;
  scheduledDate: string;
  category: string;
}
