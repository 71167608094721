import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent {
  constructor(public dialogRef: MatDialogRef<WarningModalComponent>) {}

  closeModal() {
    this.dialogRef.close(false);
  }

  continue() {
    this.dialogRef.close(true);
  }
}
