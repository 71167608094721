import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Commit, CommitApiResponse } from '../../../model/commit.model';
import { CommonService } from '../../../services/common.service';
import { jsonResponseApi } from 'src/app/model/json-response.model';
import { SymbolUploadResponse } from 'src/app/model/create-version.interface';

const baseUrl = environment.urls.apiBaseUrl;

@Injectable({
  providedIn: 'root',
})
export class CreateVersionService extends CommonService {
  getCommitList(appUuid): Observable<Commit[]> {
    return this.httpClient.get(baseUrl + `apps/${appUuid}/commits/status`).pipe(
      map((commits: { commits: CommitApiResponse[] }) => {
        return commits.commits.map((commit) => {
          return {
            author: commit.author,
            branch: commit.branch,
            commitComment: commit.commit_comment,
            commitDate: commit.commit_date,
            commitId: commit.commit_id,
            commitStatus: commit.commit_status,
            displayId: commit.display_id,
            statusDetails: commit.status_details,
          };
        });
      }),
    );
  }

  createVersion(params) {
    return this.httpClient.post<any>(baseUrl + 'release/generate', params);
  }

  getUploadUrl(): Observable<jsonResponseApi> {
    return this.httpClient.get<jsonResponseApi>(baseUrl + 'cross/SAS');
  }

  getUploadSymbolUrl(appGuid: string): Observable<SymbolUploadResponse> {
    return this.httpClient.get<SymbolUploadResponse>(
      baseUrl + `uploads/symbols_files/apps/${appGuid}`,
    );
  }
}
