import { Component, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-loading-spinner-modal',
  templateUrl: './loading-spinner-modal.component.html',
  styleUrls: ['./loading-spinner-modal.component.scss'],
})
export class LoadingSpinnerComponent {
  title: string;
  subtext: string;

  constructor(
    public dialogRef: MatDialogRef<LoadingSpinnerComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.subtext = data.subtext;
  }

  onCloseClick(): void {
    this.dialogRef.close(this.data);
  }
}
